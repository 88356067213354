import {concatExpenses} from "./concatExpenses";
import {ExpenseFolder} from "../models/expenseFolder";

export const shouldDisplayDates = (folder: ExpenseFolder) => {
	return (
		!!folder.userSetStartDate ||
		!!folder.userSetEndDate ||
		concatExpenses(folder).length > 0
	);
};
