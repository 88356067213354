import {Stack, Typography} from '@mui/material';
import {t} from 'i18next';
import ExpenseFolderComponent from './ExpenseFolderComponent';
import {useAppSelector} from '../../../app/hooks';
import {Status} from '../expenseFoldersSlice/expenseFoldersSlice';

const ExpenseFolders = () => {
	const expenseFolderSlice = useAppSelector(
		(state) => state.expenseFolderReducer,
	);

	if (expenseFolderSlice.expenseFolderStatus === Status.FailedGetting) {
		return (
			<Typography variant="body1" marginTop={'1rem'}>
				{t('error_fetching_folders')}
			</Typography>
		);
	}

	if (expenseFolderSlice.folders.length === 0) {
		return (
			<Typography variant="body1" marginTop={'1rem'}>
				{t('no_folders')}
			</Typography>
		);
	}

	return (
		<Stack spacing={2} marginTop={'1rem'}>
			{expenseFolderSlice.folders
				.slice()
				.sort((f1, f2) => (f2.id > f1.id ? 1 : -1))
				.map((folder) => (
					<ExpenseFolderComponent
						expenseFolderId={folder.id}
						key={folder.id}
					/>
				))}
		</Stack>
	);
};

export default ExpenseFolders;
