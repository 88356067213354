import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExpenseFolder } from "../models/expenseFolder";
import { addExpenseCases } from "./expenseCaseBuilders";
import { addExpenseFolderCases } from "./expenseFolderCaseBuilder";
import { ExpenseUnion as ExpenseAlias } from "../models/expense";

export enum Status {
	Fetching,
	Idle,
	Updating,
	Posting,
	Deleting,
	FailedGetting,
	FailedPosting,
	FailedPutting,
	FailedDeleting,
}

export interface ExpenseFoldersState {
	folders: ExpenseFolder[];
	expenseFolderStatus: Status;
	expenseFolderToEdit: ExpenseFolder | null;
	expenseToEdit: ExpenseAlias | null;
	expenseStatus: Status;
}

const initialState: ExpenseFoldersState = {
	folders: [],
	expenseFolderStatus: Status.Idle, //todo: look back on this
	expenseStatus: Status.Idle,
	expenseFolderToEdit: null,
	expenseToEdit: null,
};

export const ExpenseFoldersSlice = createSlice({
	name: "expenseFolders",
	initialState,
	reducers: {
		removeSentFolder: (
			state,
			action: PayloadAction<{ sentFolderId: number }>,
		) => {
			state.folders = state.folders.filter(
				(f) => f.id !== action.payload.sentFolderId,
			);
		},
		setExpenseStatus: (
			state,
			action: PayloadAction<{ status: Status }>,
		) => {
			state.expenseStatus = action.payload.status;
		},
		setExpenseFolderStatus: (
			state,
			action: PayloadAction<{ status: Status }>,
		) => {
			state.expenseFolderStatus = action.payload.status;
		},
	},
	extraReducers: (builder) => {
		addExpenseFolderCases(builder);
		addExpenseCases(builder);
	},
});

export const { removeSentFolder, setExpenseFolderStatus, setExpenseStatus } =
	ExpenseFoldersSlice.actions;

export default ExpenseFoldersSlice.reducer;
