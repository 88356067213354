import {Chip} from '@mui/material';
import {Box} from '@mui/system';
import {useTranslation} from 'react-i18next';
import {ExpenseName} from '../models/expense';

interface ExpenseTypeChipsProps {
	selectedExpenseType: string;
	handleExpenseTypeChange: (expenseType: string) => void;
	disabled: boolean;
}

const ExpenseTypeChips = (props: ExpenseTypeChipsProps) => {
	const {selectedExpenseType, handleExpenseTypeChange} = props;
	const expenseNames = Object.keys(ExpenseName)
		.filter((key) => isNaN(Number(key)))
		.map((key) => key.toString());
	const {t} = useTranslation();
	return (
		<Box justifyContent={'center'} marginTop={'0.5rem'}>
			{expenseNames.map((expenseType) => (
				<Chip
					disabled={props.disabled}
					key={expenseType}
					label={t(expenseType)}
					color={
						selectedExpenseType === expenseType
							? 'primary'
							: 'default'
					}
					onClick={() => handleExpenseTypeChange(expenseType)}
					sx={{margin: '0.3rem'}}
				/>
			))}
		</Box>
	);
};
export default ExpenseTypeChips;
