import {DispatchAlias} from "../../bhExpenses/dispatchAlias";
import {ExpenseFolder} from "../models/expenseFolder";
import {toBaseExpenseBhExpense, toFlightFromBhExpense, toOtherTransportBhExpense,} from "../../../utils/mappings";
import {Profile} from "../../profile/profile";
import createApiClient from "../../../services/createApiClient";
import {
	BergHansenM3ClientModelsWebgateGetOrdersOrder,
	BergHansenModelsExpenseBhExpense,
	BergHansenModelsExpenseM3ExpenseName,
	HttpResponse,
} from "../../../services/swaggerClient";
import {postBaseExpense, postFlight, postOtherTransport,} from "../expenseFoldersSlice/expenseThunks";
import {concatExpenses} from "./concatExpenses";
import {deleteDuplicates} from "./deleteBhExpenses";

const addBhExpenses = async (
	uniqueOrderNumbers: (number | null | undefined)[],
	profile: Profile,
	folder: ExpenseFolder,
	dispatch: DispatchAlias,
) => {
	const promises = uniqueOrderNumbers.map(async (orderNumber) => {
		const apiClient = await createApiClient();
		return apiClient.bhExpense.getExpensesOnOrderDetail(
			orderNumber!,
		);
	});
	const {bhExpenses, orders} = await getExpensesAndOrders();

	postExpenses(bhExpenses, orders, folder, dispatch);

	async function getExpensesAndOrders() {
		const expenses = await Promise.all(promises);

		const bhExpenses = expenses.flatMap((e) => e.data);

		const orderPromises = uniqueOrderNumbers.map(async (orderNumber) => {
				const apiClient = await createApiClient();
				return apiClient.bhExpense.getOrderDetail(profile.id, {
					orderNo: orderNumber!,
				});
			},
		);

		const orders = await Promise.all(orderPromises);
		return {bhExpenses, orders};
	}
};

const postExpenses = (
	bhExpenses: BergHansenModelsExpenseBhExpense[],
	orders: HttpResponse<BergHansenM3ClientModelsWebgateGetOrdersOrder>[],
	folder: ExpenseFolder,
	dispatch: DispatchAlias,
) => {
	bhExpenses.forEach(async (e) => {
		const order = orders.find(
			(o) => o.data.orderNo === e.orderNumber?.toString(),
		)!.data;
		if (e.type === BergHansenModelsExpenseM3ExpenseName.Flight) {
			const flightExpense = toFlightFromBhExpense(
				e,
				folder.id,
				order.originDisp ?? "",
				order.destDisp ?? "",
			);
			await dispatch(postFlight(flightExpense));
		} else if (BergHansenModelsExpenseM3ExpenseName.Taxi) {
			const otherTransport = toOtherTransportBhExpense(
				e,
				folder.id,
				order.originDisp ?? "",
				order.destDisp ?? "",
			);
			await dispatch(postOtherTransport(otherTransport));
		} else {
			const baseExpense = toBaseExpenseBhExpense(e, folder.id);
			await dispatch(postBaseExpense(baseExpense));
		}
	});
};

export const updateBhExpenses = (
	folder: ExpenseFolder,
	profile: Profile,
	dispatch: DispatchAlias,
) => {
	return async () => {
		const f = {...folder};

		const orderNumbers = concatExpenses(folder)
			.filter((e) => e.m3OrderNumber)
			.map((exp) => exp.m3OrderNumber);

		const uniqueOrderNumbers = Array.from(new Set(orderNumbers));

		if (uniqueOrderNumbers.length > 0) {
			await addBhExpenses(
				uniqueOrderNumbers,
				profile,
				folder,
				dispatch,
			);
			await deleteDuplicates(f, dispatch);
		}
	};
};
