import {LoadingButton} from '@mui/lab';
import {Box, Dialog, DialogContent, DialogTitle, Stack, Typography,} from '@mui/material';
import {pdf} from '@react-pdf/renderer';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {matomo} from '../../../app/MatomoTracker';
import {ExpenseFolder} from '../models/expenseFolder';
import {PdfDocument} from '../../expenseFolderPdfs/pdfDocument/PdfDocument';
import {removeSentFolder} from '../expenseFoldersSlice/expenseFoldersSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {closeSendFolderDialog, setEmailSuccessFullySent,} from '../models/expenseFoldersUiSlice';
import createApiClient from '../../../services/createApiClient';
import {calculateTotal, withExhange} from '../utils/calculateTotal';
import {concatExpenses} from '../utils/concatExpenses';
import {findDateRange} from '../../../utils/date';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CheckIcon from '@mui/icons-material/Check';
import {useNavigate} from 'react-router-dom';
import CloseIconButtonComponent from '../../../sharedComponents/CloseIconButtonComponent';
import {getExpenseFolderPdfs} from '../../expenseFolderPdfs/expenseFolderPdfsSlice';
import MissingFieldWarning from "../../profile/MissingFieldWarning";
import {toExpenseFolderDto} from "../../../utils/mappings";

const SendFolderDialog = () => {
	const [isSending, setIsSending] = useState(false);
	const folder = useAppSelector((state) =>
		state.expenseFolderReducer.folders.find(
			(folder) => folder.id === state.uiReducer.idOfFolderToSend,
		),
	);
	const profile = useAppSelector((state) => state.profileReducer.profile);
	const open = useAppSelector(
		(state) => state.uiReducer.isSendFolderDialogOpen,
	);
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const submit = async () => {
		setIsSending(true);
		await handleSubmitExpenseFolder(folder!)
			.then(async () => {
				onClose();
				dispatch(setEmailSuccessFullySent(true));
				dispatch(removeSentFolder({sentFolderId: folder!.id}));
				dispatch(getExpenseFolderPdfs());
				await new Promise((resolve) => setTimeout(resolve, 5000));
				dispatch(setEmailSuccessFullySent(null));
				navigate('/history');
			})
			.catch(() => {
				onClose();
				setIsSending(false);
				dispatch(setEmailSuccessFullySent(false));
			});
	};

	const {t} = useTranslation();

	const onClose = () => {
		dispatch(closeSendFolderDialog());
	};

	const handleSubmitExpenseFolder = async (
		fs: ExpenseFolder,
	): Promise<void> => {
		const apiService = await createApiClient();

		const folder = await withExhange(fs, profile);
		const total = await calculateTotal(folder, profile);

		const dateRange = findDateRange(folder);
		const startDate = folder.userSetStartDate ?? dateRange.startDate!;
		const endDate = folder.userSetEndDate ?? dateRange.endDate!;

		const blob = await pdf(
			<PdfDocument
				expenseFolder={folder}
				totalAmount={total}
				profile={profile}
			/>,
		).toBlob();

		const base64 = await blobToBase64(blob);

		await apiService.expenseFolderPdf.submitCreate({
			expenseFolder: toExpenseFolderDto(folder),
			pdf: {
				amount: total,
				data: base64 as string,
				description: folder.description,
				profileId: profile.id,
				id: 0,
				currencyCode: profile.currencyCode,
				createdAt: new Date().toISOString(),
				endDate: endDate,
				startDate: startDate,
			},
			sendTo: profile.sendTo!,
		});
	};

	const blobToBase64 = (blob: Blob) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});

	return (
		<Dialog open={open} onClose={onClose}>
			<CloseIconButtonComponent onClick={onClose}/>
			<DialogTitle
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				flexDirection={'column'}
				textAlign={'center'}
			>
				<EmailOutlinedIcon fontSize="large"/>
				<Typography fontWeight={600}>
					{t('send_warning_folder')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Typography variant="body1" textAlign={'center'}>
						{t('no_regret')}
					</Typography>
					{!profile.sendTo && !profile.transferOverFtp && (

						<MissingFieldWarning message={t("no_email_address")}/>
					)}

					<Box
						display={'flex'}
						justifyContent={'center'}
						marginTop="0.9rem"
					>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							onClick={async () => {
								matomo.trackEvent(
									'Submit expenseFolder',
									'click',
									'Submit expenseFolder button clicked',
									folder!.id,
								);
								await submit();
							}}
							loading={isSending}
							disabled={
								!profile.sendTo ||
								(folder && concatExpenses(folder).length === 0)
							}
						>
							<CheckIcon sx={{marginRight: '0.1rem'}}/>
							{t('sign_and_send')}
						</LoadingButton>
					</Box>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default SendFolderDialog;
