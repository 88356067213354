import {FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery,} from '@mui/material';
import {Box} from '@mui/system';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../../app/hooks';
import {matomo} from '../../../app/MatomoTracker';
import {BaseExpense, ExpenseName, ExpenseUnion, Flight, MileageAllowance, Representation} from '../models/expense';
import CurrencyList from '../../../sharedComponents/CurrencyList';
import NumberInputField from '../../../sharedComponents/NumberInputField';
import {formatDateInput} from '../../../utils/date';
import ExpenseTypeChips from './ExpenseTypeChip';

interface ExpenseFormFieldsProps {
	expense: ExpenseUnion;
	setExpense: (expense: ExpenseUnion) => void;
}

const ExpenseFormFields = (props: ExpenseFormFieldsProps) => {
	const {expense, setExpense} = props;

	const isSmallScreen = useMediaQuery('(max-width:600px)');

	const profile = useAppSelector((state) => state.profileReducer.profile);

	const shouldDisplayToFrom = (expense: ExpenseUnion) => {
		if (expense === null) return false;
		return (
			expense.expenseName === ExpenseName.Flight ||
			expense.expenseName === ExpenseName.OtherTransport ||
			expense.expenseName === ExpenseName.MileageAllowance
		);
	};

	const {t} = useTranslation();

	useEffect(() => {
		if (expense.expenseName === ExpenseName.MileageAllowance) {
			setExpense({
				...expense,
				currencyCode: 'NOK',
				amount: calculateMileageAllowance(
					(expense as MileageAllowance).distanceInKm ?? 0,
					(expense as MileageAllowance).passengerCount ?? 0,
					profile.ratePerKm,
					profile.ratePerPassenger,
				),
			});
		}
	}, [expense.expenseName]);

	const shouldDisplayRepresentation = (expense: ExpenseUnion) => {
		if (expense === null) return false;
		return expense.expenseName === ExpenseName.Representation;
	};

	const shouldDisplayMileageAllowance = (expense: ExpenseUnion) => {
		if (expense === null) return false;
		return expense.expenseName === ExpenseName.MileageAllowance;
	};

	const shouldDisplayDescription = (expense: ExpenseUnion) => {
		if (expense === null) return false;
		return (
			expense.expenseName === ExpenseName.Other ||
			expense.expenseName === ExpenseName.OtherTransport ||
			expense.expenseName === ExpenseName.Meal ||
			expense.expenseName === ExpenseName.Hotel ||
			expense.expenseName === ExpenseName.Train ||
			expense.expenseName === ExpenseName.RentalCar ||
			expense.expenseName === ExpenseName.Parking
		);
	};

	const calculateMileageAllowance = (
		distanceInKm: number,
		passengerCount: number,
		ratePerKm: number,
		ratePerPassenger: number,
	): number => {
		const amount =
			distanceInKm * ratePerKm +
			ratePerPassenger * passengerCount * distanceInKm;
		return Number(amount.toFixed(2));
	};

	const handlePassengerCountChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) =>
		setExpense({
			...expense,
			passengerCount: Number(event.target.value),
			amount: calculateMileageAllowance(
				(expense as MileageAllowance).distanceInKm ?? 0,
				Number(event.target.value),
				profile.ratePerKm,
				profile.ratePerPassenger,
			),
		});

	const handleWhoChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setExpense({...expense, who: event.target.value});

	const handleWhereChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setExpense({...expense, where: event.target.value});

	const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setExpense({...expense, startLocation: event.target.value});

	const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setExpense({...expense, endLocation: event.target.value});

	const handleDistanceInKmChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) =>
		setExpense({
			...expense,
			distanceInKm: Number(event.target.value),
			amount: calculateMileageAllowance(
				Number(event.target.value),
				(expense as MileageAllowance).passengerCount ?? 0,
				profile.ratePerKm,
				profile.ratePerPassenger,
			),
		});

	const handleExpenseNameChange = (expenseType: string) => {
		matomo.trackEvent('Expense type changed', expenseType, '', profile.id);
		return setExpense({
			...expense,
			currencyCode:
				expenseType === ExpenseName.MileageAllowance
					? 'NOK'
					: expense.currencyCode,
			expenseName: ExpenseName[expenseType as keyof typeof ExpenseName],
		});
	};

	const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setExpense({...expense, expenseDate: event.target.value});
	};

	const handleLocalAmountChange = (amount: number) => {
		return setExpense({...expense, amount: amount});
	};

	const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setExpense({...expense, description: event.target.value});

	const handleLocalCurrencyChange = (currencyCode: string) =>
		setExpense({...expense, currencyCode: currencyCode});

	const formatKm = (km: number | null | undefined) => {
		if (!km) return null;
		if (km === 0) return null;
		return km.toString();
	};

	if (expense === null) return null;

	return (
		<>
			{isSmallScreen ? (
				<FormControl fullWidth margin="normal" size="small">
					<InputLabel>{t('expense_type')}</InputLabel>
					<Select
						value={expense.expenseName ?? ExpenseName.Flight}
						onChange={(e) => {
							return handleExpenseNameChange(e.target.value);
						}}
						fullWidth
						label={t('expense_type')}
						disabled={expense.id != 0}
					>
						{GetExpenseName().map((item) => (
							<MenuItem key={item} value={item}>
								{t(item.toString())}
							</MenuItem>
						))}

					</Select>
				</FormControl>
			) : (
				<ExpenseTypeChips
					disabled={expense.id != 0}
					selectedExpenseType={expense.expenseName}
					handleExpenseTypeChange={handleExpenseNameChange}
				/>
			)}
			<TextField
				label={t('expense_date')}
				type="date"
				value={
					expense.expenseDate
						? formatDateInput(expense.expenseDate)
						: formatDateInput(new Date().toISOString())
				}
				onChange={handleDateChange}
				InputLabelProps={{
					shrink: true,
				}}
				required
			/>

			{shouldDisplayDescription(expense) && (
				<TextField
					label={t('description_optional')}
					value={(expense as BaseExpense)?.description ?? ''}
					onChange={handleCommentChange}
				/>
			)}

			{shouldDisplayToFrom(expense) && (
				<>
					<TextField
						label={t('from')}
						value={(expense as Flight)?.startLocation ?? ''}
						onChange={handleFromChange}
						required
					/>

					<TextField
						label={t('to')}
						value={(expense as Flight)?.endLocation ?? ''}
						onChange={handleToChange}
						required
					/>
				</>
			)}

			{expense?.expenseName && shouldDisplayRepresentation(expense) && (
				<>
					<TextField
						label={t('who')}
						value={(expense as Representation).who ?? ''}
						onChange={handleWhoChange}
						required
					/>
					<TextField
						label={t('where')}
						value={(expense as Representation).where ?? ''}
						onChange={handleWhereChange}
						required
					/>
				</>
			)}

			{expense?.expenseName && shouldDisplayMileageAllowance(expense) && (
				<Box display={'flex'} flexDirection={'row'}>
					<TextField
						label={t('passenger_count')}
						value={
							(
								expense as MileageAllowance
							).passengerCount?.toString() || 0
						}
						onChange={handlePassengerCountChange}
						type="number"
						sx={{marginRight: '0.5rem'}}
					/>
					<TextField
						label={t('distance')}
						value={formatKm(
							(expense as MileageAllowance).distanceInKm,
						)}
						onChange={handleDistanceInKmChange}
						type="number"
						required
					/>
				</Box>
			)}

			<Box display={'flex'}>
				<Box marginRight={'0.5rem'} width={'100%'}>
					{(expense.expenseName === ExpenseName.MileageAllowance && (
						<TextField
							required
							disabled
							label={t('amount')}
							value={expense.amount?.toString() || 0}
							type="number"
						/>
					)) || (
						<NumberInputField
							onChange={handleLocalAmountChange}
							value={expense.amount}
							label={t('amount')}
						/>
					)}
				</Box>

				<CurrencyList
					handleCurrencyChange={handleLocalCurrencyChange}
					value={expense?.currencyCode ?? 'NOK'}
					size="small"
					disableSelect={
						expense.expenseName === ExpenseName.MileageAllowance
					}
				/>
			</Box>
		</>
	);
};

export default ExpenseFormFields;

export function GetExpenseName() {
	return Object.keys(ExpenseName).filter((key) => isNaN(Number(key)));
}
