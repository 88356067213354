import {useEffect, useState} from 'react';
import {Box, Dialog, IconButton, Paper, Typography} from '@mui/material';
import {ExpenseFolderPdf} from './expenseFolderPdf';
import {base64ToPDFFile, downloadFile} from '../expenseFolders/utils/file';
import {FileDownloadOutlined} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {formatNumberWithSpaces} from '../../utils/formatNumberWithSpaces';
import ResizingButton from '../../sharedComponents/ResizingButton';
import {Api} from "../../services/swaggerClient";
import createApiClient from '../../services/createApiClient';

type PdfBoxProps = {
	title: string;
	dateRange: string;
	total: number;
	expenseFolderPdf: ExpenseFolderPdf;
};

export const PdfBox = ({
						   title,
						   dateRange,
						   total,
						   expenseFolderPdf,
					   }: PdfBoxProps) => {
	const [open, setOpen] = useState(false);

	const [apiClient, setApiClient] = useState<Api<unknown> | null>(null);

	useEffect(() => {
		createApiClient().then(client => {
			setApiClient(client);
		});
	}, []);
	const {t} = useTranslation();

	return (
		<>
			<Box
				display="flex"
				flexDirection={'column'}
				justifyContent="space-between"
			>
				<Paper sx={{padding: 4}}>
					<Box display="flex">
						<Box flexGrow={1}>
							<Typography variant="h6">{title}</Typography>
							<Typography variant="body2">{dateRange}</Typography>
						</Box>

						<Box>
							<IconButton
								onClick={() => {

									const data =
										expenseFolderPdf.source ===
										'TravelExpense'
											? apiClient!.travelExpense.getExpenseFolderPdfDetail(
												expenseFolderPdf.id,
											)
											: apiClient!.expenseFolderPdf.getExpenseFolderPdf(
												expenseFolderPdf.id,
											);
									data.then((efp) => {
										downloadFile(
											base64ToPDFFile(
												efp.data.data,
												expenseFolderPdf.description ??
												'',
											),
										);
									});
								}}
							>
								<FileDownloadOutlined/>
							</IconButton>
						</Box>
					</Box>

					<Box
						display="flex"
						fontWeight="700"
						marginTop={3}
						paddingBottom={1}
						borderBottom={'1px solid'}
						borderColor={'grey.100'}
					>
						<Typography fontWeight="inherit" flexGrow={1}>
							{t('total')}
						</Typography>
						<Typography fontWeight="inherit">
							{formatNumberWithSpaces(total)}{' '}
							{expenseFolderPdf.currencyCode}
						</Typography>
					</Box>
				</Paper>
			</Box>

			<Dialog open={open} onClose={() => setOpen(false)}>
				<iframe
					src={expenseFolderPdf.data}
					width="100%"
					height="700pxp"
					title="pdf"
				/>
				<ResizingButton
					color="primary"
					variant="contained"
					onClick={() => setOpen(false)}
				>
					Close
				</ResizingButton>
			</Dialog>
		</>
	);
};
