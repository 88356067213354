import React from 'react';
import { Stack, Typography } from '@mui/material';
import { findDateDifference, formatDate } from '../utils/date';
import Loading from './Loading';
import { useAppSelector } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import BackToHomeLink from '../sharedComponents/BackToHomeLink';
import { PdfBox } from '../features/expenseFolderPdfs/PdfBox';

export const Pdfs: React.FC = () => {
	const pdfs = useAppSelector(
		(state) => state.expenseFolderPdfReducer.pdfsExpense,
	);
	const pdfsOld = useAppSelector(
		(state) => state.expenseFolderPdfReducer.pdfsOld,
	);
	const { t } = useTranslation();
	if (!pdfs) return <Loading />;

	return (
		<>
			<BackToHomeLink />
			<Typography variant="h5" marginBottom={2} fontWeight={600}>
				{t('sent_expenses')}
			</Typography>
			<Typography variant="body1" marginBottom={2}>
				{t('sent_expenses_text')}{' '}
			</Typography>
			<Stack spacing={2}>
				{[...pdfs, ...pdfsOld]
					.slice()
					.sort((p1, p2) =>
						findDateDifference(p1.createdAt!, p2.createdAt!),
					)
					.map((pdf) => (
						<PdfBox
							key={
								pdf.id +
								pdf.amount +
								(pdf.description ?? '') +
								pdf.startDate
							}
							title={pdf.description ?? ''}
							dateRange={
								formatDate(pdf.startDate) +
								' - ' +
								formatDate(pdf.endDate)
							}
							total={pdf.amount}
							expenseFolderPdf={pdf}
						/>
					))}
			</Stack>
		</>
	);
};

export default Pdfs;
