import { DispatchAlias } from "../../bhExpenses/dispatchAlias";
import { ExpenseFolder } from "../models/expenseFolder";
import { deleteExpense } from "../expenseFoldersSlice/expenseThunks";
import { concatExpenses } from "./concatExpenses";

export const deleteDuplicates = async (
	f: ExpenseFolder,
	dispatch: DispatchAlias,
) => {
	await Promise.all(
		concatExpenses(f)
			.filter((e) => e.m3OrderNumber)
			.map(async (exp) => {
				return await dispatch(deleteExpense(exp.id));
			}),
	);
};
