import {DeleteOutlined, FileDownloadOutlined} from '@mui/icons-material';
import {IconButton, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {ExpenseUnion} from '../models/expense';
import createApiClient from '../../../services/createApiClient';
import {base64ToFile, downloadFile} from '../utils/file';
import {Attachment} from "../models/attachments";


interface AttachmentListProps {
	expense: ExpenseUnion;
	setExpense: (expense: ExpenseUnion) => void;
}

const getAttachmentFileName = (attachment: any): string => {
	const extension = attachment.mediaType.split('/')?.[1];
	return attachment.description ?? `attachment.${extension}`;
};

const downloadAttachmentData = (data: string, fileName: string) => {
	downloadFile(base64ToFile(data, fileName));
};

const formatFileName = (fileName: string): string => {
	const parts = fileName.split('.');
	if (parts.length < 2) return fileName;

	const namePart = parts[0];
	const extensionPart = parts[parts.length - 1];

	if (namePart.length <= 10) return fileName;

	const formattedName = `${namePart.substring(0, 20)}...${namePart.substring(
		namePart.length - 3,
	)}`;
	return `${formattedName}.${extensionPart}`;
};

const AttachmentList = (props: AttachmentListProps) => {
	const {expense, setExpense} = props;

	const handleDownloadClick = async (attachment: Attachment) => {
		try {
			//download attachment if it is stored in the backend
			const client = await createApiClient()
			const res = await client.attachment.getAttachment(attachment.id);
			downloadAttachmentData(res.data.data!, getAttachmentFileName(attachment));
		} catch (error) {
			if (attachment.data) {
				//download attachment if it is not stored in the backend
				downloadAttachmentData(attachment.data, getAttachmentFileName(attachment));
			}
		}
	};

	return (
		<Box>
			{expense?.attachments?.map((attachment, index) => (
				<Box key={index} alignItems={'center'} display={'flex'}>
					<Typography
						onClick={() => handleDownloadClick(attachment)}
						sx={{
							cursor: 'pointer',
						}}
					>
						{formatFileName(attachment.description)}
					</Typography>
					<IconButton
						onClick={() =>
							setExpense({
								...expense,
								attachments: expense.attachments?.filter((a) => a !== attachment),
							})
						}
					>
						<DeleteOutlined/>
					</IconButton>
					<IconButton
						onClick={() => handleDownloadClick(attachment)}
					>
						<FileDownloadOutlined/>
					</IconButton>
				</Box>
			))}
		</Box>
	);
};

export default AttachmentList;
