import {TFunction} from "i18next";
import {
	BaseExpense,
	ExpenseName,
	ExpenseUnion,
	Flight,
	MileageAllowance,
	OtherTransport,
	Representation,
} from "../models/expense";
import {Profile} from "../../profile/profile";

export const ExpenseDescription = (
	expense: ExpenseUnion,
	translate: TFunction,
	profile: Profile,
): string => {
	const {expenseName} = expense;

	const formatLocation = (start: string, end: string) =>
		`${start} ${translate("to").toLowerCase()} ${end}`;

	switch (expenseName) {
		case ExpenseName.Flight:
			const flight = expense as Flight;
			return formatLocation(flight.startLocation, flight.endLocation);

		case ExpenseName.MileageAllowance:
			const {startLocation, endLocation, distanceInKm, passengerCount} =
				expense as MileageAllowance;
			return `${translate(
				"car_trip_from",
			)} ${startLocation} ${formatLocation(
				"",
				endLocation,
			)} - ${distanceInKm} km ${translate(
				"with",
			)} ${passengerCount} ${translate("passengers")}. ${translate(
				"price_per_km",
			)}: ${profile.ratePerKm.toFixed(2)} NOK`;

		case ExpenseName.Representation:
			const {who, where} = expense as Representation;
			return `${who} - ${where}`;

		case ExpenseName.OtherTransport:
			const otherTransport = expense as OtherTransport;

			return `${
				otherTransport.description && otherTransport.description + " -" || ""
			} ${formatLocation(
				otherTransport.startLocation,
				otherTransport.endLocation,
			)}`;

		default:
			return (
				(expense as BaseExpense).description?.trim() ??
				translate(expenseName)
			);
	}
};
