import React from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { useAppSelector } from '../app/hooks';

interface CurrencyListComponentProps {
	value: string;
	handleCurrencyChange: (currencyCode: string) => void;
	size: 'small' | 'large';
	disableSelect?: boolean;
}

const CurrencyList: React.FC<CurrencyListComponentProps> = ({
	handleCurrencyChange,
	value,
	size,
	disableSelect,
}) => {
	const currencies = useAppSelector(
		(state) => state.currencyReducer.currencyList,
	);
	return (
		<Autocomplete
			disableClearable
			options={currencies}
			getOptionLabel={(option) => option.code}
			value={currencies.find((currency) => currency.code === value)}
			onChange={(_, currency) => {
				if (currency) handleCurrencyChange(currency.code);
			}}
			disabled={disableSelect === true}
			renderInput={(params) => (
				<TextField
					{...params}
					fullWidth
					label="Valuta"
					disabled={disableSelect === true}
					sx={{
						width: size == 'small' ? '6rem' : '100%',
					}}
				/>
			)}
		/>
	);
};

export default CurrencyList;
