import { DeleteOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResizingButton from "../../sharedComponents/ResizingButton";
import LoadingButton from "../../sharedComponents/LoadingButton";
import {matomo} from "../../app/MatomoTracker";

interface EditDialogButtonsProps {
	deleteItem: () => void;
	isSaving: boolean;
	buttonText: string;
	isEditButtonDisabled: boolean;
}

const EditDialogButtons = (props: EditDialogButtonsProps) => {
	const {
		isSaving,
		deleteItem,
		buttonText,
		isEditButtonDisabled,
	} = props;

	const { t } = useTranslation();

	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			justifyContent={'right'}
			marginTop={'0.7rem'}
		>
			<ResizingButton
				variant="text"
				onClick={() => deleteItem()}
				style={{
					color: 'rgb(97, 97, 97)',
					fontWeight: 500,
				}}
			>
				{t(buttonText)}
				<DeleteOutlined />
			</ResizingButton>
			<LoadingButton
				type="submit"
				variant="contained"
				color="primary"
				loading={isSaving}
				disabled={isEditButtonDisabled}
				onClick={() => {
					 matomo.trackEvent('Add', 'click', t(buttonText));
				}}
			>
				{t('save')}
			</LoadingButton>
		</Box>
	);
};

export default EditDialogButtons;
