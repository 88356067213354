import React from 'react';
import {Box, CircularProgress, IconButton, Typography} from '@mui/material';
import {FileUploadOutlined} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import createApiClient from '../../services/createApiClient';
import AlertSnackbar from '../../sharedComponents/AlertSnackbar';
import {fileToBase64} from './expense/AddExpenseForm';


interface AttachmentProps {
	onAttachmentUpload: (file: File) => void;
}

const PhotoCapture: React.FC<AttachmentProps> = ({onAttachmentUpload}) => {
	const {t} = useTranslation();

	const hiddenFileInput = React.useRef<HTMLInputElement>(null);

	// Define the allowed MIME types for validation
	const allowedMimeTypes = [
		'image/jpeg',
		'image/jpg',
		'image/png',
		'application/pdf',
	];

	const [isUploadAttachmentAlert, setIsUploadAttachmentAlert] =
		React.useState<boolean>(false);

	// Function to validate the uploaded file
	const validateImageFile = (file: File): boolean => {
		return allowedMimeTypes.includes(file.type);
	};

	const [isValidating, setIsValidating] = React.useState<boolean>(false);

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (!file) return;

		if (!validateImageFile(file)) {
			setIsUploadAttachmentAlert(true);
			hiddenFileInput.current!.value = '';
			return;
		}

		setIsValidating(true);

		try {
			const base64 = await fileToBase64(file);

			const apiClient = await createApiClient(
			)
			const res = await apiClient.attachment.validateCreate({
				description: file.name,
				data: base64,
				mediaType: file.type,
				expenseId: 0,
				id: 0,
				url: '',
			});

			if (!res.data.isValid) {
				setIsUploadAttachmentAlert(true);
				hiddenFileInput.current!.value = '';
				setIsValidating(false);
				return;
			}

			onAttachmentUpload(file);

			setIsValidating(false);
			event.target.value = '';
		} catch (error) {
			setIsValidating(false);
		}
	};

	return (
		<>
			<input
				type="file"
				onChange={handleFileChange}
				style={{display: 'none'}}
				ref={hiddenFileInput}
				accept=".pdf, .jpg, .jpeg, .png"
			/>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'center'}
				alignItems={'center'}
				onClick={() => hiddenFileInput.current!.click()}
				sx={{
					paddingTop: '1.3rem',
					paddingBottom: '1.3rem',
					border: '2px dashed',
					borderColor: 'rgba(0, 0, 0, 0.12)', // Black with 12% opacity
					borderRadius: '5px',
					cursor: 'pointer',
				}}
			>
				{(isValidating && <CircularProgress/>) || (
					<>
						<Typography
							style={{
								marginRight: '0.3rem',
							}}
						>
							{t('take_photo')}
						</Typography>
						<Typography>{t('or')}</Typography>

						<Typography
							style={{
								marginLeft: '0.3rem',
							}}
						>
							{t('upload_file')}
						</Typography>
						<IconButton>
							<FileUploadOutlined/>
						</IconButton>
					</>
				)}
			</Box>
			<AlertSnackbar
				open={isUploadAttachmentAlert}
				onClose={() => setIsUploadAttachmentAlert(false)}
				textCode={'upload_file_error'}
				severity={'error'}
			/>
		</>
	);
};

export default PhotoCapture;
