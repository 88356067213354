import { useAuth0, User } from "@auth0/auth0-react";
import { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { setGetAccessTokenSilently } from "../services/tokenService";
import type { RootState, AppDispatch } from "./store";
import {getProfileByTacNo, postProfile} from "../features/profile/profileSlice";
import { setIsFirstTimeLogin } from "../features/expenseFolders/models/expenseFoldersUiSlice";
import { getCurrencies } from "../features/currency/currencySlice";
import {getExpenseFolders} from "../features/expenseFolders/expenseFoldersSlice/expenseFolderThunks";
import {
	getExpenseFolderPdfs,
	getExpenseFolderPdfsTravelExpense
} from "../features/expenseFolderPdfs/expenseFolderPdfsSlice";
import {getBhOrders} from "../features/bhExpenses/bhExpensesSlice";
import {matomo} from "./MatomoTracker";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useInit = (user: User | undefined) => {
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
	const dispatch = useAppDispatch();

	useEffect(() => {
		const init = async (u: User) => {
			setGetAccessTokenSilently(getAccessTokenSilently);
			let profile;
			try {
				profile = await dispatch(getProfileByTacNo(u.TacNo)).unwrap();
			} catch {
				profile = await dispatch(postProfile(u)).unwrap();
				dispatch(setIsFirstTimeLogin(true));
			}
			await dispatch(getCurrencies());
			await dispatch(getExpenseFolders());
			await dispatch(getExpenseFolderPdfs());
			await dispatch(getExpenseFolderPdfsTravelExpense());
			await dispatch(
				getBhOrders({
					orderCount: 6,
					profileId: profile.id!,
				}),
			);
			matomo.trackEvent('Login', 'user successfully logged in', '', profile.tacNo);
		};

		if (user) {
			init(user);
		}
	}, [user, dispatch, getAccessTokenSilently]);
};

export const usePageTitle = () => {
	const location = useLocation();
	const { t } = useTranslation();

	useEffect(() => {
		const titleMap: Record<string, string> = {
			'/settings': `${t('expense')} | ${t('settings')}`,
			'/history': `${t('expense')} | ${t('history')}`,
		};

		document.title = titleMap[location.pathname] || `${t('expense')} | ${t('home')}`;
	}, [location.pathname, t]);
};
