import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ExpenseFoldersState, Status } from "./expenseFoldersSlice";
import {
	deleteExpenseFolder,
	getExpenseFolders,
	postExpenseFolder,
	putExpenseFolder,
} from "./expenseFolderThunks";

export const addExpenseFolderCases = (
	builder: ActionReducerMapBuilder<ExpenseFoldersState>,
) => {
	builder
		.addCase(getExpenseFolders.pending, (state) => {
			state.expenseFolderStatus = Status.Fetching;
		})
		.addCase(getExpenseFolders.fulfilled, (state, action) => {
			state.expenseFolderStatus = Status.Idle;
			state.folders = action.payload;
		})
		.addCase(getExpenseFolders.rejected, (state) => {
			state.expenseFolderStatus = Status.FailedGetting;
		})
		.addCase(postExpenseFolder.pending, (state) => {
			state.expenseFolderStatus = Status.Posting;
		})
		.addCase(postExpenseFolder.fulfilled, (state, action) => {
			state.expenseFolderStatus = Status.Idle;
			state.folders.push(action.payload);
		})
		.addCase(postExpenseFolder.rejected, (state) => {
			state.expenseFolderStatus = Status.FailedPosting;
		})
		.addCase(putExpenseFolder.pending, (state) => {
			state.expenseFolderStatus = Status.Updating;
		})
		.addCase(putExpenseFolder.fulfilled, (state, action) => {
			state.expenseFolderStatus = Status.Idle;
			const index = state.folders.findIndex(
				(f) => f.id === action.payload.id,
			);
			state.folders[index] = action.payload;
		})
		.addCase(putExpenseFolder.rejected, (state) => {
			state.expenseFolderStatus = Status.FailedPutting;
		})
		.addCase(deleteExpenseFolder.pending, (state) => {
			state.expenseFolderStatus = Status.Deleting;
		})
		.addCase(deleteExpenseFolder.fulfilled, (state, action) => {
			state.expenseFolderStatus = Status.Idle;
			state.folders = state.folders.filter(
				(f) => f.id !== action.payload,
			);
		})
		.addCase(deleteExpenseFolder.rejected, (state) => {
			state.expenseFolderStatus = Status.FailedDeleting;
		});
};
