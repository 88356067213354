import './App.css';
import {useEffect} from 'react';
import {useAuth0, User} from '@auth0/auth0-react';
import {Route, Routes, useLocation} from 'react-router-dom';
import Layout from './sharedComponents/Layout';
import Exception from './pages/Error';
import Pdfs from './pages/Pdfs';
import Loading from './pages/Loading';
import NotFound from './pages/NotFound';
import Settings from './pages/Profile';
import {getProfileByTacNo, postProfile, ProfileStatus} from './features/profile/profileSlice';
import {useAppDispatch, useAppSelector} from './app/hooks';
import {getExpenseFolders} from './features/expenseFolders/expenseFoldersSlice/expenseFolderThunks';
import {setIsFirstTimeLogin} from './features/expenseFolders/models/expenseFoldersUiSlice';
import {useTranslation} from 'react-i18next';
import {getBhOrders} from './features/bhExpenses/bhExpensesSlice';
import {matomo} from './app/MatomoTracker';
import {getCurrencies} from './features/currency/currencySlice';
import {
	getExpenseFolderPdfs,
	getExpenseFolderPdfsTravelExpense
} from './features/expenseFolderPdfs/expenseFolderPdfsSlice';
import ExpenseFoldersPage from './pages/ExpenseFoldersPage';
import {setGetAccessTokenSilently} from './services/tokenService';
import {ErrorBoundary} from 'react-error-boundary';
import {apm} from './index';

// Fallback component to render when an error is caught
const ErrorFallback: React.FC<{ error: Error; resetErrorBoundary: () => void }> = ({error, resetErrorBoundary}) => {
	return (
		<Exception title="Unexpected Error" description={error.message} displayLoggout={true}/>
	);
};

const App = () => {
	const {loginWithRedirect, isAuthenticated, isLoading, error, user, getAccessTokenSilently} = useAuth0();
	const profileReducer = useAppSelector((state) => state.profileReducer);
	const {i18n, t} = useTranslation();
	const dispatch = useAppDispatch();
	const location = useLocation();

	const capitalizeFirstLetter = (input: string): string => {
		if (input.length === 0) return input;
		return input.charAt(0).toUpperCase() + input.slice(1);
	};

	useEffect(() => {
		const init = async (u: User) => {
			setGetAccessTokenSilently(getAccessTokenSilently);
			let profile;
			try {
				profile = await dispatch(getProfileByTacNo(u.TacNo)).unwrap();
			} catch {
				profile = await dispatch(postProfile(u)).unwrap();
				dispatch(setIsFirstTimeLogin(true));
			}
			await dispatch(getExpenseFolders());
			await dispatch(getCurrencies());
			await dispatch(getExpenseFolderPdfs());
			await dispatch(getExpenseFolderPdfsTravelExpense());
			await dispatch(getBhOrders({orderCount: 6, profileId: profile.id!}));
			matomo.trackEvent('Login', 'user successfully logged in', '', profile.tacNo);
		};
		if (user) {
			init(user);
		}
	}, [user]);

	useEffect(() => {
		matomo.trackPageView(location.pathname);
		if (location.pathname === '/settings')
			document.title = capitalizeFirstLetter(t('expense')) + ' | ' + t('settings');
		else if (location.pathname === '/history')
			document.title = capitalizeFirstLetter(t('expense')) + ' | ' + t('history');
		else
			document.title = capitalizeFirstLetter(t('expense')) + ' | ' + t('home');
	}, [location]);

	useEffect(() => {
		if (profileReducer.profile.languageCode?.toLowerCase() === 'no') i18n.changeLanguage('no');
		else i18n.changeLanguage('en');
	}, [profileReducer.profile.languageCode]);

	if (
		error?.name.toLowerCase() === 'invalid state' ||
		error?.name.toLowerCase() === 'login_required' ||
		error?.message.toLowerCase() === 'invalid state' ||
		error?.message.toLowerCase() === 'login_required'
	) {
		loginWithRedirect();
		return <Loading message="Authenticating..."/>;
	}

	if (isLoading || profileReducer.status === ProfileStatus.Getting) return <Loading/>;

	if (error !== undefined)
		return <Exception title={error.name} description={error.message} displayLoggout={true}/>;

	if (profileReducer.status === ProfileStatus.FailedGetting)
		return <Exception title="500" description="Failed to get profile from server." displayLoggout={true}/>;

	if (!isAuthenticated) {
		loginWithRedirect();
		return <Loading message="Authenticating..."/>;
	}

	return (
		<ErrorBoundary
			fallbackRender={({error, resetErrorBoundary}) => (
				<ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary}/>
			)}
			onError={(err: Error, info) => {
				// Log the error to Elastic APM if available
				if (apm) {
					apm.captureError(err);
				}
				console.error('ErrorBoundary caught an error', err, info);
			}}
		>
			<Layout>
				<Routes>
					<Route path="/" element={<ExpenseFoldersPage/>}/>
					<Route path="/history" element={<Pdfs/>}/>
					<Route path="/settings" element={<Settings/>}/>
					<Route path="*" element={<NotFound/>}/>
				</Routes>
			</Layout>
		</ErrorBoundary>
	);
};

export default App;
