import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import './index.css';
import App from './App';
import {Auth0Provider} from '@auth0/auth0-react';
import {store} from './app/store';
import './app/i18n';
import {init as initApm} from '@elastic/apm-rum';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Monitor from './pages/Monitor';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

export const apm =
	process.env.NODE_ENV === 'production'
		? initApm({
			serviceName: 'Expense',
			serverUrl:
				'https://438a429bf63847019df6c867c908c9cd.apm.westeurope.azure.elastic-cloud.com:443',
			serviceVersion: '',
			environment: 'PRODUCTION',
		})
		: null;

// Create a wrapper component for authenticated routes
const ProtectedApp: React.FC = () => (
	<Auth0Provider
		domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
		clientId={process.env.REACT_APP_AUTH0_CLIENTID as string}
		authorizationParams={{
			redirect_uri: window.location.origin,
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		}}
	>
		<Provider store={store}>
			<App/>
		</Provider>
	</Auth0Provider>
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				{/* Public route: /monitor returns the Monitor page */}
				<Route path="/monitor" element={<Monitor/>}/>
				{/* All other routes use the ProtectedApp wrapper */}
				<Route path="/*" element={<ProtectedApp/>}/>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
