import {BaseExpense, ExpenseUnion, Flight, MileageAllowance, OtherTransport, Representation,} from "../models/expense";
import {ExpenseFolder} from "../models/expenseFolder";
import {Profile} from "../../profile/profile";
import createApiClient from "../../../services/createApiClient";
import {Api} from "../../../services/swaggerClient";
import {concatExpenses} from "./concatExpenses";

interface ExchangeRequest {
	amount: number;
	sourceCurrencyCode: string;
	targetCurrencyCode: string;
	date: string;
}

export const exchange =
	(service: Api<unknown>, profile: Profile) =>
		async (expense: ExpenseUnion): Promise<ExpenseUnion> => {
			if (expense.currencyCode === profile.currencyCode)
				return {
					...expense,
					exchangeDetails: {
						amountInTargetCurrency: expense.amount,
						exchangeRate: 1,
						targetCurrency: profile.currencyCode,
					},
				};
			const exchangeRequest: ExchangeRequest = {
				amount: expense.amount,
				sourceCurrencyCode: expense.currencyCode,
				targetCurrencyCode: profile.currencyCode,
				date:
					new Date(expense.expenseDate) > new Date()
						? new Date().toISOString()
						: new Date(expense.expenseDate).toISOString(),
			};
			const exchangeResult = await service.currency.exchangeList({
				amount: exchangeRequest.amount,
				sourceCurrencyCode: exchangeRequest.sourceCurrencyCode,
				targetCurrencyCode: exchangeRequest.targetCurrencyCode,
				date: exchangeRequest.date,
			});

			const effectiveRate = parseFloat(
				(
					(exchangeResult.data.valueInTargetCurrency! / expense.amount) *
					(1 + profile.creditCardFee / 100)
				).toFixed(2),
			);

			return {
				...expense,
				exchangeDetails: {
					amountInTargetCurrency: expense.amount * effectiveRate,
					exchangeRate: effectiveRate,
					targetCurrency: profile.currencyCode,
				},
			};
		};

export const withExhange = async (
	folder: ExpenseFolder,
	profile: Profile,
) => {
	const service = await createApiClient();

	const exchangedExpenses = await Promise.all(
		concatExpenses(folder).map(exchange(service, profile)),
	);

	const baseExpenses = folder.baseExpenses.map(
		(expense) =>
			exchangedExpenses.find(
				(ex) => ex.id === expense.id,
			)! as BaseExpense,
	);
	const mileageAllowances = folder.mileageAllowances.map(
		(expense) =>
			exchangedExpenses.find(
				(ex) => ex.id === expense.id,
			)! as MileageAllowance,
	);
	const otherTransports = folder.otherTransports.map(
		(expense) =>
			exchangedExpenses.find(
				(ex) => ex.id === expense.id,
			)! as OtherTransport,
	);
	const representations = folder.representations.map(
		(expense) =>
			exchangedExpenses.find(
				(ex) => ex.id === expense.id,
			)! as Representation,
	);
	const flight = folder.flight.map(
		(expense) =>
			exchangedExpenses.find((ex) => ex.id === expense.id)! as Flight,
	);

	return {
		...folder,
		folder,
		mileageAllowances,
		otherTransports,
		representations,
		flight,
		baseExpenses,
	};
};

export const calculateTotal = async (
	folder: ExpenseFolder,
	profile: Profile,
) => {
	const apiService = await createApiClient();
	const c = await Promise.all(
		concatExpenses(folder).map((expense) => {
			if (expense.exchangeDetails) return expense;
			return exchange(apiService, profile)(expense);
		}),
	);
	return c.reduce((acc, curr) => {
		if (curr.exchangeDetails?.exchangeRate !== 1)
			return acc + curr.exchangeDetails!.amountInTargetCurrency;
		return acc + curr.exchangeDetails.amountInTargetCurrency;
	}, 0);
};
