import {Alert, Box, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import React from "react";
import { useTranslation } from 'react-i18next';


interface Props {
	message: string;
}

const MissingFieldWarning: React.FC<Props> = ({ message }) => {
	const { t } = useTranslation();
	return (
		<Alert severity="error" variant="standard">
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				flexDirection={'row'}
				alignItems={'center'}
			>
				<Typography width={'60%'}>
					{message}
				</Typography>
				<Link
					sx={{ textDecoration: 'none' }}
					component={RouterLink}
					to={'/settings'}
					color="inherit"
				>
					<Box display={'flex'} flexDirection={'row'}>
						<Typography
							sx={{
								textDecoration: 'underline',
							}}
						>
							{t('settings')}
						</Typography>
						<ArrowForwardRoundedIcon />
					</Box>
				</Link>
			</Box>
		</Alert>
	)
}

export default MissingFieldWarning;
