import { IconButton, Typography, Paper, Box, Divider } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { findEndDate, findStartDate } from '../../../utils/date';

import { concatExpenses } from '../utils/concatExpenses';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { openEditFolderDialog } from '../models/expenseFoldersUiSlice';
import { useTranslation } from 'react-i18next';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { ExpenseFolder } from '../models/expenseFolder';
import { matomo } from '../../../app/MatomoTracker';
import ExpenseComponent from '../expense/ExpenseComponent';
import SubmitButton from './SubmitButton';
import UpdateBhExpensesButton from './UpdateBhExpensesButton';
import TotalAmount from './TotalAmount';
import AddExpenseButton from './AddExpenseButton';
import {shouldDisplayDates} from "../utils/displayDates";

interface ExpenseFolderProps {
	expenseFolderId: number;
}

const ExpenseFolderComponent: React.FC<ExpenseFolderProps> = ({
	expenseFolderId,
}) => {
	const folder = useAppSelector((state) => {
		const expenseFolder = state.expenseFolderReducer.folders.find(
			(folder) => folder.id === expenseFolderId,
		);
		return expenseFolder;
	})!;

	const { t } = useTranslation();

	const [displayDates, setDisplayDates] = useState<boolean>(false);

	useEffect(() => {
		setDisplayDates(shouldDisplayDates(folder))
	}, [folder]);

	const dispatch = useAppDispatch();

	return (
		<Paper sx={{ padding: '2rem' }}>
			<Box>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography variant="h6">{folder.description} </Typography>
					<IconButton
						onClick={() => {
							matomo.trackEvent(
								'Edit Folder',
								'click',
								'Edit expenseFolder button clicked',
								expenseFolderId,
							);
							return dispatch(openEditFolderDialog(folder.id));
						}}
					>
						<EditNoteOutlinedIcon titleAccess={t('edit_expense')} />
					</IconButton>
				</Box>

				{displayDates &&(<Typography marginBottom={'1.5rem'}>
					{findStartDate(folder)}
					{' - '}
					{findEndDate(folder)}
				</Typography>)}

				{concatExpenses(folder)
					.sort((a, b) => a.id - b.id)
					.map((expense) => (
						<ExpenseComponent key={expense.id} expense={expense} />
					))}
				<AddExpenseButton folder={folder} />
				<TotalAmount folder={folder} />
				<Divider sx={{ marginTop: '0.1rem', marginBottom: '1rem' }} />
			</Box>

			<Box display="flex">
				<UpdateBhExpensesButton folder={folder} />
				<SubmitButton folder={folder} />
			</Box>
		</Paper>
	);
};

export default ExpenseFolderComponent;
