import { t } from 'i18next';
import { ExpenseFolder } from '../models/expenseFolder';
import { openSendFolderDialog } from '../models/expenseFoldersUiSlice';
import { concatExpenses } from '../utils/concatExpenses';
import CheckIcon from '@mui/icons-material/Check';
import { useAppDispatch } from '../../../app/hooks';
import ResizingButton from '../../../sharedComponents/ResizingButton';
import React from "react";

interface SubmitButtonProps {
	folder: ExpenseFolder;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ folder }) => {
	const dispatch = useAppDispatch();
	return (
		<ResizingButton
			onClick={() => {
				dispatch(openSendFolderDialog(folder.id));
			}}
			variant="contained"
			color="primary"
			disabled={concatExpenses(folder).length === 0}
			sx={{ marginLeft: 'auto' }}
		>
			{concatExpenses(folder).length != 0 && (
				<CheckIcon sx={{ marginRight: '0.1rem' }} />
			)}
			{concatExpenses(folder).length === 0
				? t('missing_receipt')
				: t('submit')}
		</ResizingButton>
	);
};

export default SubmitButton;
