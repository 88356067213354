import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Profile} from "./profile";
import {toProfile, toProfileDto, toProfileDtoFromUser,} from "../../utils/mappings";
import {User} from "@auth0/auth0-react";
import createApiClient from "../../services/createApiClient";

export enum ProfileStatus {
	Idle,
	Getting,
	Posting,
	Putting,
	NotFound,
	FailedGetting,
	FailedPosting,
	FailedPutting,
	SuccessPutting,
}

const initialState: ProfileState = {
	profile: {
		currencyCode: "USD",
		id: 0,
		email: "",
		firstName: "",
		lastName: "",
		tacNo: 0,
		bankAccount: "",
		languageCode: "",
		creditCardFee: 0,
		ratePerKm: 0,
		ratePerPassenger: 0,
		sendTo: "",
		transferOverFtp: false,
	},
	status: ProfileStatus.Idle,
};

export interface ProfileState {
	profile: Profile;
	status: ProfileStatus;
}

export const getProfileByTacNo = createAsyncThunk(
	"profile/getProfile",
	async (tacNo: number) => {
		const apiClient = await createApiClient()
		const res = await apiClient.profile.getByTacNoDetail(tacNo);
		console.log(res);
		return res.data;
	},
);

export const putProfile = createAsyncThunk(
	"profile/putProfile",
	async (profile: Profile) => {
		const client = await createApiClient()
		await client.profile.updateUpdate(toProfileDto(profile));
		return toProfile(profile);
	},
);

export const postProfile = createAsyncThunk(
	"profile/postProfile",
	async (profile: User) => {
		const apiClient = await createApiClient()
		const res = await apiClient.profile.createCreate(
			toProfileDtoFromUser(profile),
		);
		return toProfile(res.data);
	},
);

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setProfileStatus: (state, action) => {
			state.status = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getProfileByTacNo.pending, (state) => {
				state.status = ProfileStatus.Getting;
			})
			.addCase(getProfileByTacNo.fulfilled, (state, action) => {
				console.log(action.payload);
				state.status = ProfileStatus.Idle;

				state.profile = toProfile(action.payload);
			})
			.addCase(getProfileByTacNo.rejected, (state, action) => {
				state.status =
					action.error.code == "404"
						? ProfileStatus.NotFound
						: ProfileStatus.FailedGetting;
			})
			.addCase(putProfile.pending, (state) => {
				state.status = ProfileStatus.Putting;
			})
			.addCase(putProfile.fulfilled, (state, action) => {
				state.status = ProfileStatus.SuccessPutting;
				state.profile = toProfile(action.payload);
			})
			.addCase(putProfile.rejected, (state) => {
				state.status = ProfileStatus.FailedPutting;
			})
			.addCase(postProfile.pending, (state) => {
				state.status = ProfileStatus.Posting;
			})
			.addCase(postProfile.fulfilled, (state, action) => {
				state.status = ProfileStatus.Idle;
				state.profile = toProfile(action.payload);
			})
			.addCase(postProfile.rejected, (state) => {
				state.status = ProfileStatus.FailedPosting;
			});
	},
});

export const {setProfileStatus} = profileSlice.actions;

export default profileSlice.reducer;
