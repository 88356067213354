import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setEmailSuccessFullySent } from './models/expenseFoldersUiSlice';
import {
	Status,
	setExpenseStatus, // Consider renaming in the slice for clarity.
	setExpenseFolderStatus, // Make sure the slice exports these clearly.
} from './expenseFoldersSlice/expenseFoldersSlice';
import AlertSnackbar from '../../sharedComponents/AlertSnackbar';

interface SnackbarConfig {
	open: boolean;
	onClose?: () => void;
	textCode: string;
	severity: 'success' | 'error';
}

const HomeSnackbars = () => {
	const dispatch = useAppDispatch();
	const emailSuccess = useAppSelector(
		(state) => state.uiReducer.emailSuccessFullySent,
	);
	const expenseFolderSlice = useAppSelector(
		(state) => state.expenseFolderReducer,
	);
	const exchangeRateStatus = useAppSelector(
		(state) => state.uiReducer.exchangeRateStatus,
	);

	const handleEmailClose = useCallback(
		() => dispatch(setEmailSuccessFullySent(null)),
		[dispatch],
	);
	const handleExpenseStatusClose = useCallback(
		() => dispatch(setExpenseStatus({ status: Status.Idle })),
		[dispatch],
	);
	const handleExpenseFolderStatusClose = useCallback(
		() => dispatch(setExpenseFolderStatus({ status: Status.Idle })),
		[dispatch],
	);

	const alertConfigs: SnackbarConfig[] = [
		{
			open: emailSuccess === true,
			onClose: handleEmailClose,
			textCode: 'email_sent',
			severity: 'success',
		},
		{
			open: emailSuccess === false,
			onClose: handleEmailClose,
			textCode: 'email_not_sent',
			severity: 'error',
		},
		{
			open: expenseFolderSlice.expenseStatus === Status.FailedPosting,
			onClose: handleExpenseStatusClose,
			textCode: 'error_posting_expense',
			severity: 'error',
		},
		{
			open: expenseFolderSlice.expenseStatus === Status.FailedPutting,
			onClose: handleExpenseStatusClose,
			textCode: 'error_updating_expense',
			severity: 'error',
		},
		{
			open: expenseFolderSlice.expenseStatus === Status.FailedDeleting,
			onClose: handleExpenseFolderStatusClose,
			textCode: 'error_deleting_expenseFolder',
			severity: 'error',
		},
		{
			open: expenseFolderSlice.expenseFolderStatus === Status.FailedPosting,
			onClose: handleExpenseFolderStatusClose,
			textCode: 'error_posting_expenseFolder',
			severity: 'error',
		},
		{
			open: expenseFolderSlice.expenseFolderStatus === Status.FailedPutting,
			onClose: handleExpenseFolderStatusClose,
			textCode: 'error_updating_folder',
			severity: 'error',
		},
		{
			open: expenseFolderSlice.expenseFolderStatus === Status.FailedDeleting,
			onClose: handleExpenseFolderStatusClose,
			textCode: 'error_deleting_folder',
			severity: 'error',
		},
		{
			open: exchangeRateStatus === Status.FailedGetting,
			// If you want this alert to be closable, add an onClose handler
			textCode: 'failed_getting_exchange_rates',
			severity: 'error',
		},
	];

	return (
		<>
			{alertConfigs.map((config, index) => (
				<AlertSnackbar
					key={index}
					open={config.open}
					onClose={config.onClose}
					textCode={config.textCode}
					severity={config.severity}
				/>
			))}
		</>
	);
};

export default HomeSnackbars;
