import {configureStore} from "@reduxjs/toolkit";
import profileReducer from "../features/profile/profileSlice";
import currencyReducer from "../features/currency/currencySlice";
import bhExpensesReducer from "../features/bhExpenses/bhExpensesSlice";

import expenseFolderReducer from "../features/expenseFolders/expenseFoldersSlice/expenseFoldersSlice";
import expenseFolderPdfReducer from "../features/expenseFolderPdfs/expenseFolderPdfsSlice";
import uiReducer from "../features/expenseFolders/models/expenseFoldersUiSlice";

export const store = configureStore({
	reducer: {
		profileReducer: profileReducer,
		currencyReducer: currencyReducer,
		expenseFolderReducer: expenseFolderReducer,
		expenseFolderPdfReducer: expenseFolderPdfReducer,
		uiReducer: uiReducer,
		bhExpensesReducer: bhExpensesReducer,
	},
	devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
