import {
	Text,
	View,
} from '@react-pdf/renderer';

interface ShadedTextBoxProps {
	textInBoxOne: string;
	textInBoxTwo: string;
}

import { styles } from './PdfDocument';

export const ShadedTextBox = (props: ShadedTextBoxProps) => {
	return (
		<View style={styles.flexRow}>
			<View style={styles.shadedTextBox}>
				<Text>{props.textInBoxOne}</Text>
			</View>
			<View style={styles.shadedTextBox}>
				<Text>{props.textInBoxTwo}</Text>
			</View>
		</View>
	);
};
