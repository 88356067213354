/**
 * A small helperclass to set defaults for the swagger client
 */
import {Api} from "./swaggerClient";
import {getTokenSilently} from "./tokenService";

export default async function createApiClient() {
	const token = await getTokenSilently();
	return new Api({
		baseUrl: process.env.REACT_APP_API_BASE_URL as string,
		baseApiParams: {
			headers: {
				Authorization: "Bearer " + token,
			},
		},
	});
}
