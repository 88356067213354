import { Snackbar, Alert } from '@mui/material';
import { t } from 'i18next';

interface AlertSnackbarProps {
	open: boolean | null;
	onClose?: () => void;
	textCode: string;
	severity: 'success' | 'error';
}

const AlertSnackbar: React.FC<AlertSnackbarProps> = ({
	open: successfullSend,
	onClose,
	textCode,
	severity,
}) => {
	return (
		<Snackbar open={successfullSend === true} onClose={onClose}>
			<Alert severity={severity}>{t(textCode)}</Alert>
		</Snackbar>
	);
};

export default AlertSnackbar;
