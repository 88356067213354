import {createAsyncThunk} from "@reduxjs/toolkit";
import {BaseExpense, Flight, MileageAllowance, OtherTransport, Representation,} from "../models/expense";
import {
	toBaseExpense,
	toBaseExpenseDto,
	toFlight,
	toFlightDto,
	toMileageAllowance,
	toMileageAllowanceDto,
	toOtherTransport,
	toOtherTransportDto,
	toRepresentation,
	toRepresentationDto,
} from "../../../utils/mappings";
import createApiClient from "../../../services/createApiClient";
import {Attachment} from "../models/attachments";

export const postMileageAllowance = createAsyncThunk(
	"expenseFolders/postMileageAllowance",
	async (mileageAllowance: MileageAllowance) => {
		const client = await createApiClient()
		const response = await client.mileageAllowance.createCreate(
			toMileageAllowanceDto(mileageAllowance),
		);
		return toMileageAllowance(response.data);
	},
);

export const putMileageAllowance = createAsyncThunk(
	"expenseFolders/putMileageAllowance",
	async (mileageAllowance: MileageAllowance) => {

		const client = await createApiClient()
		await client.mileageAllowance.updateUpdate(
			toMileageAllowanceDto(mileageAllowance),
		);
		return mileageAllowance;
	},
);

export const postBaseExpense = createAsyncThunk(
	"expenseFolders/postBaseExpense",
	async (baseExpense: BaseExpense) => {
		const client = await createApiClient()
		const response = await client.baseExpense.createCreate(
			toBaseExpenseDto(baseExpense),
		);
		return toBaseExpense(response.data);
	},
);

export const putBaseExpense = createAsyncThunk(
	"expenseFolders/putBaseExpense",
	async (baseExpense: BaseExpense) => {

		const client = await createApiClient()
		await client.baseExpense.updateUpdate(
			toBaseExpenseDto(baseExpense),
		);
		return baseExpense;
	},
);

export const postFlight = createAsyncThunk(
	"expenseFolders/postFlight",
	async (baseExpense: Flight) => {
		const client = await createApiClient()
		const response = await client.flight.createCreate(
			toFlightDto(baseExpense),
		);
		return toFlight(response.data);
	},
);

export const putFlight = createAsyncThunk(
	"expenseFolders/putFlight",
	async (flight: Flight) => {

		const client = await createApiClient()
		await client.flight.updateUpdate(toFlightDto(flight));
		return flight;
	},
);

export const postRepresentation = createAsyncThunk(
	"expenseFolders/postRepresentation",
	async (representation: Representation) => {

		const client = await createApiClient()
		const response = await client.representation.createCreate(
			toRepresentationDto(representation),
		);
		return toRepresentation(response.data);
	},
);

export const putRepresentation = createAsyncThunk(
	"expenseFolders/putRepresentation",
	async (representation: Representation) => {

		const client = await createApiClient()
		await client.representation.updateUpdate(
			toRepresentationDto(representation),
		);
		return representation;
	},
);

export const postOtherTransport = createAsyncThunk(
	"expenseFolders/postOtherTransport",
	async (otherTransport: OtherTransport) => {

		const client = await createApiClient()
		const response = await client.otherTransport.createCreate(
			toOtherTransportDto(otherTransport),
		);
		return toOtherTransport(response.data);
	},
);

export const putOtherTransport = createAsyncThunk(
	"expenseFolders/putOtherTransport",
	async (otherTransport: OtherTransport) => {

		const client = await createApiClient()
		await client.otherTransport.updateUpdate(
			toOtherTransportDto(otherTransport),
		);
		return otherTransport;
	},
);

export const deleteExpense = createAsyncThunk(
	"expenseFolders/deleteExpense",
	async (expenseId: number) => {

		const client = await createApiClient()
		await client.expense.deleteDelete(expenseId);
		return expenseId;
	},
);

export const deleteAttachment = createAsyncThunk(
	"expenseFolders/deleteAttachment",
	async (attachmentId: number) => {

		const client = await createApiClient()
		await client.attachment.deleteDelete(attachmentId);
		return attachmentId;
	},
);

export const postAttachment = createAsyncThunk(
	"expenseFolders/postAttachment",
	async (attachment: Attachment) => {

		const client = await createApiClient()
		const response = await client.attachment.createCreate(
			attachment,
		);
		return response.data;
	},
);
