import moment from "moment";
import { ExpenseFolder } from "../features/expenseFolders/models/expenseFolder";
import { concatExpenses } from "../features/expenseFolders/utils/concatExpenses";

type StringNullUndefined = string | undefined | null;

export const formatDate = (date: StringNullUndefined) => {
	if (!date) {
		return "";
	}
	return moment(date).format("DD.MM.yyyy");
};

export const formatDateInput = (date: string) => {
	return moment(new Date(date)).format("yyyy-MM-DD");
};

export const formatTimePeriod = (
	start: StringNullUndefined,
	end: StringNullUndefined,
) => {
	if (!start || !end) {
		return "";
	}
	return `${formatDate(start)} - ${formatDate(end)}`;
};

export const findStartDate = (folder: ExpenseFolder) => {
	if (folder.userSetStartDate) {
		return formatDate(folder.userSetStartDate);
	}
	if (findDateRange(folder).startDate) {
		return formatDate(findDateRange(folder).startDate);
	}
	if (folder.userSetEndDate) {
		return formatDate(folder.userSetEndDate);
	}
	return "";
};

export const findEndDate = (folder: ExpenseFolder) => {
	if (folder.userSetEndDate) {
		return formatDate(folder.userSetEndDate);
	}
	if (findDateRange(folder).endDate) {
		return formatDate(findDateRange(folder).endDate);
	}
	if (folder.userSetStartDate) {
		return formatDate(folder.userSetStartDate);
	}
	return "";
};

export const findDateRange = (expenseFolder: ExpenseFolder) => {
	const sortedExpenses = concatExpenses(expenseFolder).sort(
		(e1, e2) =>
			new Date(e1.expenseDate).getTime() -
			new Date(e2.expenseDate).getTime(),
	);
	if (sortedExpenses.length === 0) return { startDate: null, endDate: null };
	const startDate = sortedExpenses[0].expenseDate;
	const endDate = sortedExpenses[sortedExpenses.length - 1].expenseDate;
	return { startDate, endDate };
};

export const findDateDifference = (
	dateString1: string,
	dateString2: string,
): number => {
	const date1 = new Date(dateString1);
	const date2 = new Date(dateString2);

	if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
		throw new Error("Invalid date string");
	}

	return date2.getTime() - date1.getTime();
};
