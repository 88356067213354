import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: {
				translation: {
					welcome_message: "Welcome to your expenses",
					welcome_text:
						"Here you can add all expenses you have from your trip!",
					expense_folders: "Unsubmitted expenses",
					new_folder: "New expense",
					take_photo: "Take photo",
					upload_file: "Upload file",
					or: "or",
					add_expense: "Add receipt",
					berg_hansen_travel: "Berg-Hansen travel",
					add: "Add",
					submit: "Sign and submit",
					expense_type: "Receipt type",
					date: "Date",
					amount_including_vat: "Amount including VAT",
					description: "Description",
					cancel: "Cancel",
					email_sent: "Your email has been sent",
					email_sent_error: "There was an error sending your email",
					edit_expense_folder: "Edit expense ",
					name: "Title of expense",
					from_date: "From date",
					to_date: "To date",
					delete_expense_folder: "Delete expense",
					delete_expense: "Delete receipt",
					delete: "Delete",
					save: "Save changes",
					amount: "Amount",
					description_optional: "Description (optional)",
					from: "From",
					to: "To",
					who: "Who",
					where: "Where",
					passenger_count: "Passenger count",
					distance: "Distance in km",
					rate_per_km: "Rate per km",
					rate_per_passenger: "Rate per passenger",
					settings: "Settings",
					send_to: "Send to",
					your_email: "Your email",
					account_number: "Account number",
					recipient_email: "Recipient email",
					default_currency: "Default currency",
					currency_fee: "Currency conversion fee",
					your_changes_have_been_saved:
						"Your changes have been saved",
					error_updating_settings:
						"There was an error updating your settings",
					create: "Create",
					navigate_to: "Navigate to",
					old_solution: "old solution",
					see_history: "to see past history",
					overview_of_previous_expenses:
						"Here you have an overview of previous expenses",
					history: "History",
					new_expense_folder: "New expense",
					no_regret: "You can not regret this",
					send_inn_folder:
						"Are you sure you want to send for signature?",
					sign_and_send: "Sign and send",
					contain_at_least_one_expense:
						"The expense must contain at least one receipt",
					home: "Home",
					no_email_address: "Fill out receiving email",
					log_out: "Log out",
					total: "Total",
					order_trip: "Order trip",
					privacy_statement: "Privacy statement / Cookies",
					Other: "Other",
					OtherTransport: "Other Transport",
					RentalCar: "Rental Car",
					MileageAllowance: "Mileage Allowance",
					Flight: "Flight",
					Train: "Train",
					Meal: "Meal",
					Representation: "Representation",
					Hotel: "Hotel",
					edit_receipt: "Edit receipt",
					Parking: "Parking",
					sent_expenses_text:
						"Here you have an overview of all the expenses you have submitted.",
					here: "here",
					sent_expenses: "Submitted Expenses",
					missing_receipt: "Missing receipt",
					new_receipt: "New receipt",
					berg_hansen_travels: "Berg-Hansen travels",
					add_expense_folder: "Add expense",
					close: "Close",
					load_more_orders: "Load more travels",
					reference_number: "Reference number",
					payed_by: "Payed by",
					purpose_with_expense: "Purpose with expense",
					amount_in: "Amount in",
					currency: "Currency",
					authorized_signature: "Authorized signature",
					signature: "Signature",
					incl: "Incl.",
					send_warning_folder:
						"Are you sure you want to send for signature?",
					exchangeRate: "Rate",
					update_bh_expenses: "Update Berg Hansen expenses",
					expenses: "Expenses",
					settings_warning_part1:
						"Travel Expense has been upgraded. You can still make changes in the",
					settings_warning_part2:
						"until 17.06.24, after that all started invoices will be lost.",
					expense_date: "Expense date",
					previously_submitted: "Submitted expenses",
					receiving_email_tooltip:
						"The email address the expense will be sent to. Often this will often be an accountant or your closest leader.",
					rate_per_km_tooltip:
						"Rate per km is the sum you get back when driving. Rate per passenger is the addition that will be added per kilometer when you have passengers in the car.",
					currency_fee_tooltip:
						"This is the fee that is added when you pay with a card abroad.",
					destination: "Destination",
					outward_date: "Outward date",
					failed_getting_exchange_rates:
						"Failed to get exchange rates, please try again later",
					back_to_home: "Back to expense",
					edit_expense: "Edit expense",
					delete_attachment: "Delete attachment",
					download_attachment: "Download attachment",
					has_attachments: "Has attachments",
					car_trip_from: "Car trip from",
					price_per_km: "Price per km",
					passengers: "passengers",
					email_not_sent: "Email not sent",
					upload_file_error:
						"Can not upload file. Please upload a PDF, JPEG, JPG, or PNG file.",
					no_folders: "You have no unsubmitted expenses.",
					max_expenses:
						"You can not add more receipts to this expenseFolder",
					max_attachments:
						"You can not add more attachments to the expense",
					loading: "Loading...",
					error_fetching_expenseFolders: "Error fetching folders",
					error_updating_expenseFolder: "Error updating expenseFolder",
					error_deleting_expenseFolder: "Error deleting expense",
					error_posting_expenseFolder: "Error creating expense",
					error_updating_expense: "Error updating expense",
					error_deleting_expense: "Error deleting receipt",
					error_posting_expense: "Error posting receipt",
					send_pdf_email: "Send expense as PDF on email",
					send_pdf_as_ehf: "Send expense as EHF",
					must_have_bank_account_number: "Account number needs to be filled out to send expense as EHF",
					loading_expenses: "Loading expenses"
				},
			},
			no: {
				translation: {
					welcome_message: "Velkommen til dine utlegg",
					welcome_text:
						"Her kan du laste opp og sende inn utlegg, kjøregodtgjørelser og reise etter regning!",
					expense_folders: "Påbegynte utlegg",
					new_folder: "Nytt utlegg",
					take_photo: "Ta bilde",
					upload_file: "Last opp",
					or: "eller",
					add_expense: "Legg til kvittering",
					berg_hansen_travel: "Berg-Hansen reise",
					add: "Legg til",
					submit: "Lever utlegg",
					expense_type: "Kvitteringstype",
					date: "Dato",
					expense_date: "Utleggsdato",
					amount_including_vat: "Beløp inkludert MVA",
					description: "Beskrivelse",
					cancel: "Avbryt",
					email_sent: "Din e-post er sendt",
					email_sent_error:
						"Det oppstod en feil ved sending av e-post",
					edit_expense_folder: "Rediger utlegg",
					edit_receipt: "Rediger Kvittering",
					name: "Navn på utlegg",
					from_date: "Fra dato",
					to_date: "Til dato",
					delete_expense_folder: "Slett utlegg",
					delete_expense: "Slett kvittering",
					delete: "Slett",
					save: "Lagre endringer",
					amount: "Beløp",
					description_optional: "Beskrivelse (valgfritt)",
					from: "Fra",
					to: "Til",
					who: "Hvem",
					where: "Hvor",
					passenger_count: "Antall passasjerer",
					distance: "Lengde i km",
					settings: "Innstillinger",
					send_to: "Send til",
					your_email: "Din e-post",
					account_number: "Kontonummer",
					recipient_email: "Mottaker e-post",
					rate_per_km: "Pris per km",
					default_currency: "Standard valuta",
					rate_per_passenger: "Pris per passasjer",
					currency_fee: "Valutapåslag",
					your_changes_have_been_saved: "Dine endringer er lagret",
					error_updating_settings:
						"Det oppstod en feil ved oppdatering av innstillinger",
					create: "Opprett",
					navigate_to: "Naviger til",
					old_solution: "gammel løsning",
					see_history: "for å se tidligere historikk",
					overview_of_previous_expenses:
						"Her har du oversikt over dine tidligere utlegg",
					history: "Historikk",
					new_expense_folder: "Nytt utlegg",
					no_regret: "Du kan ikke angre dette",
					send_warning_folder:
						"Er du sikker på at du vil sende til signering?",
					sign_and_send: "Signer og send",
					contain_at_least_one_expense:
						"Et utlegg må inneholde minst en kvittering",
					home: "Hjem",
					no_email_address: "Fyll ut mottaker e-post",
					log_out: "Logg ut",
					total: "Total",
					order_trip: "Bestill reise",
					privacy_statement: "Personværnerklæring / Cookies",
					Other: "Annet",
					OtherTransport: "Annen transport",
					RentalCar: "Leiebil",
					MileageAllowance: "Kjøregodtgjørelse",
					Flight: "Fly",
					Train: "Tog",
					Meal: "Måltid",
					Representation: "Representasjon",
					Hotel: "Hotell",
					Parking: "Parkering",
					sent_expenses_text:
						"Her har du oversikt over alle utleggene du har levert inn.",
					here: "her",
					sent_expenses: "Leverte Utlegg",
					missing_receipt: "Mangler kvittering",
					new_receipt: "Ny kvittering",
					berg_hansen_travels: "Berg-Hansen reiser",
					add_expense_folder: "Legg til utlegg",
					close: "Lukk",
					load_more_orders: "Last inn flere reiser",
					reference_number: "Referanse nr",
					payed_by: "Betalt av",
					purpose_with_expense: "Formål med utlegg",
					amount_in: "Beløp i",
					currency: "Valuta",
					authorized_signature: "Autorisert signatur",
					signature: "Signatur",
					incl: "Inkl.",
					exchangeRate: "Kurs",
					update_bh_expenses: "Oppdater Berg Hansen utlegg",
					expenses: "Utlegg",
					settings_warning_part1:
						"Enkel reiseregning har fått en oppgradering. Du kan fortsatt gjøre endringer i",
					settings_warning_part2:
						" til 17.06.24, etter det vil alle påbegynte regninger gå tapt.",
					previously_submitted: "Leverte utlegg",
					receiving_email_tooltip:
						"E-postadressen utlegget vil bli sendt til. Ofte vil dette være regnskapsfører eller din nærmeste leder.",
					rate_per_km_tooltip:
						"Gjelder når du leverer kjøregodtgjørelse. Pris per kilometer er summen du får tilbake ved kjøring. Pris per passasjer er tillegget som blir lagt på per kilometer når du har passasjerer i bilen",
					currency_fee_tooltip:
						"Dette er gebyret som legges på når du betaler med kort i utlandet.",
					destination: "Destinasjon",
					outward_date: "Utreisedato",
					failed_getting_exchange_rates:
						"Kunne ikke hente valutakurser, prøv igjen senere",
					back_to_home: "Tilbake til utlegg",
					edit_expense: "Rediger utlegg",
					delete_attachment: "Slett vedlegg",
					download_attachment: "Last ned vedlegg",
					has_attachments: "Har vedlegg",
					car_trip_from: "Biltur fra",
					price_per_km: "pris per km",
					passengers: "passasjerer",
					email_not_sent: "E-post ikke sendt",
					upload_file_error:
						"Kan ikke laste opp filtype. Vennligst last opp en PDF, JPEG, JPG eller PNG-fil.",
					no_folders: "Du har ingen påbegynte utlegg.",
					max_expenses:
						"Du kan ikke legge til flere kvitteringer i dette utlegget",
					max_attachments:
						"Du kan ikke legge til flere vedlegg på utlegget",
					loading: "Laster...",
					error_fetching_expenseFolders: "Feil ved henting av utlegg",
					error_updating_expenseFolder:
						"Feil ved oppdatering av utlegg",
					error_deleting_expenseFolder: "Feil ved sletting av utlegg",
					error_posting_expenseFolder: "Feil ved oppretting av utlegg",
					error_updating_expense:
						"Feil ved oppdatering av kvittering",
					error_posting_expense: "Feil ved laging av kvittering",
					send_pdf_email: "Send utlegg på e-post",
					send_pdf_as_ehf: "Send utlegg som EHF",
					error_deleting_expense: "Feil ved sletting av kvittering",
					must_have_bank_account_number: "Kontonummer må fylles ut for å sende utlegget som EHF",
					loading_expenses: "Laster utlegg"
				},
			},
		},
		lng: "en", // if you're using a language detector, you can remove this line
		fallbackLng: "no",

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
