import {Box, CircularProgress, Typography} from '@mui/material';
import {t} from 'i18next';
import {formatNumberWithSpaces} from '../../../utils/formatNumberWithSpaces';
import {useAppSelector} from '../../../app/hooks';
import {ExpenseFolder} from '../models/expenseFolder';
import {useEffect, useState} from 'react';
import {calculateTotal} from '../utils/calculateTotal';
import {shouldDisplayCreditCardFee} from '../../expenseFolderPdfs/utils/shouldDisplayCreditCardFee';

const TotalAmount: React.FC<{ folder: ExpenseFolder }> = ({folder}) => {
	const profile = useAppSelector((state) => state.profileReducer.profile);
	const [total, setTotal] = useState<number | null>(0);

	useEffect(() => {
		const calculate = async () => {
			const total = await calculateTotal(
				folder,
				profile,
			);
			setTotal(total);
		};
		calculate();
	}, [folder, profile]);

	return (
		<Box display={'flex'} justifyContent={'space-between'}>
			<Typography variant="body2" fontWeight={600}>
				TOTAL{' '}
				{shouldDisplayCreditCardFee(profile, folder)
					? '(' +
					t('incl') +
					' ' +
					t('currency_fee').toLowerCase() +
					')'
					: ''}
			</Typography>
			<Typography variant="body2" fontWeight={600}>
				{total !== null ? (
					formatNumberWithSpaces(total) + ' ' + profile.currencyCode
				) : (
					<CircularProgress size={18}/>
				)}
			</Typography>
		</Box>
	);
};

export default TotalAmount;
