import {Box, InputAdornment, Tooltip, useMediaQuery} from '@mui/material';
import {t} from 'i18next';
import {Profile} from './profile';
import React, {useState} from 'react';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import NumberInputField from '../../sharedComponents/NumberInputField';

interface RateFieldsProps {
	profile: Profile;
	handleRatePerKmChange: (e: number) => void;
	handleRatePerPassengerChange: (e: number) => void;
}

export const RateFields: React.FC<RateFieldsProps> = ({
														  profile,
														  handleRatePerKmChange,
														  handleRatePerPassengerChange,
													  }) => {
	const [isTooltipRatePerKmOpen, setIsTooltipRatePerKmOpen] = useState(false);
	const [isTooltipRatePerPassengerOpen, setIsTooltipRatePerPassengerOpen] =
		useState(false);
	const [
		isTooltipKmAndRatePerPassengerOpen,
		setIsTooltipKmAndRatePerPassengerOpen,
	] = useState(false);
	const isOnPhone = useMediaQuery('(max-width:600px)');
	return (
		<Box display={'flex'} alignItems={'center'}>
			<Tooltip
				title={t('rate_per_km_tooltip')}
				open={isTooltipRatePerKmOpen}
				onMouseLeave={() => setIsTooltipRatePerKmOpen(false)}
				onMouseEnter={() => {
					if (!isOnPhone) return setIsTooltipRatePerKmOpen(true);
				}}
			>


				<NumberInputField
					label={t('rate_per_km')}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">NOK</InputAdornment>
						),
					}}
					onChange={handleRatePerKmChange}
					value={profile.ratePerKm}
					sx={{marginRight: '0.4rem'}}
				></NumberInputField>
			</Tooltip>
			<Tooltip
				title={t('rate_per_km_tooltip')}
				open={isTooltipRatePerPassengerOpen}
				onMouseLeave={() => setIsTooltipRatePerPassengerOpen(false)}
				onMouseEnter={() => {
					if (!isOnPhone) setIsTooltipRatePerPassengerOpen(true);
				}}
			>
				<NumberInputField
					label={t('rate_per_passenger')}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">NOK</InputAdornment>
						),
					}}
					InputLabelProps={{shrink: true}}
					onChange={handleRatePerPassengerChange}
					value={profile.ratePerPassenger}
					sx={{marginLeft: '0.4rem'}}
				></NumberInputField>
			</Tooltip>

			<Tooltip
				title={t('rate_per_km_tooltip')}
				open={isTooltipKmAndRatePerPassengerOpen}
			>
				<HelpOutlineRoundedIcon
					fontSize="small"
					sx={{marginLeft: '0.4rem', marginTop: '0.4rem'}}
					onClick={() =>
						setIsTooltipKmAndRatePerPassengerOpen(
							!isTooltipKmAndRatePerPassengerOpen,
						)
					}
					onMouseLeave={() =>
						setIsTooltipKmAndRatePerPassengerOpen(false)
					}
				/>
			</Tooltip>
		</Box>
	);
};
