
let getAccessTokenSilentlyFn: (() => Promise<string>) | null = null;

export const setGetAccessTokenSilently = (
	fn: () => Promise<string>,
): void => {
	getAccessTokenSilentlyFn = fn;
};

export const getTokenSilently = async (): Promise<string> => {
	if (!getAccessTokenSilentlyFn) {
		throw new Error('Auth0 getAccessTokenSilently function not initialized');
	}
	return getAccessTokenSilentlyFn();
};
