import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {BergHansenM3ClientModelsWebgateGetOrdersOrder} from "../../services/swaggerClient";
import createApiClient from "../../services/createApiClient";

export enum BhOrdersStatus {
	Idle,
	Getting,
	Updating,
	FailedGetting,
}

const initialState: BhOrdersState = {
	orders: [],
	status: BhOrdersStatus.Idle,
};

export interface BhOrdersState {
	orders: BergHansenM3ClientModelsWebgateGetOrdersOrder[];
	status: BhOrdersStatus;
}

export const getBhOrders = createAsyncThunk(
	"bhExpenses/getBhOrders",
	async (query: { orderCount: number; profileId: number }, thunkApi) => {
		const client = await createApiClient()
		const res = await client.bhExpense.getNonProcessedOrderDetail(query.profileId, {
			orderCount: query.orderCount,
		});
		return res.data;
	},
);

export const processOrder = createAsyncThunk(
	"bhExpenses/processOrder",
	async (orderId: number, thunkApi) => {
		const client = await createApiClient()
		await client.bhExpense.addProcessedOrderCreate(orderId);
		return orderId;
	},
);

export const bhExpensesSlice = createSlice({
	name: "bhExpenses",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getBhOrders.pending, (state) => {
				state.status = BhOrdersStatus.Getting;
			})
			.addCase(getBhOrders.fulfilled, (state, action) => {
				state.orders = action.payload ?? [];
				state.status = BhOrdersStatus.Idle;
			})
			.addCase(getBhOrders.rejected, (state) => {
				state.status = BhOrdersStatus.Idle;
			})
			.addCase(processOrder.pending, (state) => {
				state.status = BhOrdersStatus.Updating;
			})
			.addCase(processOrder.fulfilled, (state, action) => {
				state.status = BhOrdersStatus.Idle;
				state.orders = state.orders.filter(
					(order) => parseInt(order.orderNo!) !== action.payload,
				);
			});
	},
});

export default bhExpensesSlice.reducer;
