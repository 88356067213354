import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {ExpenseFolder} from '../models/expenseFolder';
import {Profile} from '../../profile/profile';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {concatExpenses} from '../utils/concatExpenses';
import {updateBhExpenses} from '../utils/updateBhExpenses';

const UpdateBhExpensesButton: React.FC<{ folder: ExpenseFolder }> = ({
																		 folder,
																	 }) => {
	const {t} = useTranslation();
	const profile: Profile = useAppSelector(
		(state) => state.profileReducer.profile,
	);
	const dispatch = useAppDispatch();
	const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
	const [hasBergHansenExpenses, setHasBergHansenExpenses] = useState(false);
	useEffect(() => {
		if (concatExpenses(folder).some((e) => e.m3OrderNumber)) {
			setHasBergHansenExpenses(true);
		} else {
			setHasBergHansenExpenses(false);
		}
	}, [folder]);
	if (!hasBergHansenExpenses) {
		return null;
	}

	return (
		<LoadingButton
			loading={isUpdatingOrder}
			variant="text"
			sx={{marginLeft: '-0.5rem', textAlign: 'left'}}
			onClick={() => {
				setIsUpdatingOrder(true);
				updateBhExpenses(folder, profile, dispatch);
				setIsUpdatingOrder(false);
			}}
		>
			{t('update_bh_expenses')}
		</LoadingButton>
	);
};

export default UpdateBhExpensesButton;
