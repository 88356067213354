import { Flight, ExpenseName } from "../models/expense";

export const initExpense = (number: number): Flight => {
	return {
		id: 0,
		amount: 0,
		currencyCode: "NOK",
		expenseReportId: number,
		expenseName: ExpenseName.Flight,
		expenseDate: new Date().toISOString(),
		attachments: [],
		createdAt: new Date().toISOString(),
		endLocation: "",
		startLocation: "",
	};
};
