import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogTitle, TextField} from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {ExpenseFolder} from '../models/expenseFolder';
import {Status} from '../expenseFoldersSlice/expenseFoldersSlice';
import {deleteExpenseFolder, putExpenseFolder,} from '../expenseFoldersSlice/expenseFolderThunks';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {closeEditFolderDialog} from '../models/expenseFoldersUiSlice';
import {findDateRange, formatDateInput} from '../../../utils/date';
import CloseIconButtonComponent from '../../../sharedComponents/CloseIconButtonComponent';
import EditDialogButtons from '../EditDialogButtons';

const EditExpenseFolderDialog = () => {
	const [expenseFolderDraft, setExpenseFolderDraft] =
		useState<ExpenseFolder>();
	const [endDateString, setEndDateString] = useState<string | null>(null);
	const [startDateString, setStartDateString] = useState<string | null>(null);

	const expenseFolderToEdit = useAppSelector((state) => {
		if (state.uiReducer.expenseFolderToEditId) {
			return state.expenseFolderReducer.folders.find(
				(folder) => folder.id === state.uiReducer.expenseFolderToEditId,
			);
		}
	});

	const {calculatedStartDate, calculatedEndDate} = useAppSelector(
		(state) => {
			const folder = state.expenseFolderReducer.folders.find(
				(folder) => folder.id === state.uiReducer.expenseFolderToEditId,
			);

			if (!folder) return {startDate: null, endDate: null};

			const {startDate, endDate} = findDateRange(folder);

			return {
				calculatedStartDate: startDate,
				calculatedEndDate: endDate,
			};
		},
	);

	const dispatch = useAppDispatch();

	const translation = useTranslation();

	const getStartDate = (folder: ExpenseFolder) => {
		return folder.userSetStartDate ?? calculatedStartDate;
	};

	const getEndDate = (folder: ExpenseFolder) => {
		return folder.userSetEndDate ?? calculatedEndDate;
	};

	const isLoading = useAppSelector(
		(state) => state.expenseFolderReducer.expenseFolderStatus,
	);
	const open = useAppSelector(
		(state) => state.uiReducer.isEditExpenseFolderDialogOpen,
	);

	useEffect(() => {
		setExpenseFolderDraft(expenseFolderToEdit);
	}, [expenseFolderToEdit]);

	useEffect(() => {
		if (expenseFolderDraft) {
			const startDate = getStartDate(expenseFolderDraft!) ?? getEndDate(expenseFolderDraft!);
			const endDate = getEndDate(expenseFolderDraft!) ?? getStartDate(expenseFolderDraft!);
			setStartDateString(formatDateInput(startDate ?? endDate ?? new Date().toISOString()));
			setEndDateString(formatDateInput(endDate ?? startDate ?? new Date().toISOString()));
		}

	}, [expenseFolderDraft])

	const handleStartDateChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setExpenseFolderDraft({
			...expenseFolderDraft!,
			userSetStartDate: new Date(event.target.value).toISOString(),
		});
	};

	const handleEndDateChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setExpenseFolderDraft({
			...expenseFolderDraft!,
			userSetEndDate: new Date(event.target.value).toISOString(),
		});
	};

	const handleFolderNameChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setExpenseFolderDraft({
			...expenseFolderDraft!,
			description: event.target.value,
		});
	};


	const submitFolderForm = async (
		event: React.FormEvent<HTMLFormElement>,
	) => {
		event.preventDefault();
		await dispatch(putExpenseFolder(expenseFolderDraft!));
		closeDialog();
	};

	const closeDialog = () => {
		dispatch(closeEditFolderDialog());
	};

	if (!expenseFolderToEdit || !expenseFolderDraft) return null;

	return (
		<Dialog open={open} onClose={closeDialog}>
			<CloseIconButtonComponent onClick={closeDialog}/>
			<DialogTitle
				display={'flex'}
				alignItems={'center'}
				marginTop="0rem"
				paddingTop="0rem"
			>
				<FolderOutlinedIcon sx={{marginRight: '0.4rem'}}/>
				{translation.t('edit_expense_folder')}
			</DialogTitle>

			<DialogContent>
				<form onSubmit={async (e) => await submitFolderForm(e)}>
					<TextField
						autoFocus
						id="name"
						label={translation.t('name')}
						type="text"
						value={expenseFolderDraft?.description}
						onChange={handleFolderNameChange}
						required
					/>
					<TextField
						label={translation.t('from_date')}
						type="date"
						value={
							startDateString}
						onChange={handleStartDateChange}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<TextField
						label={translation.t('to_date')}
						type="date"
						value={
							endDateString}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={handleEndDateChange}
					/>
					<EditDialogButtons
						deleteItem={() => {
							dispatch(
								deleteExpenseFolder(expenseFolderToEdit.id),
							);
							closeDialog();
						}}
						buttonText="delete_expense_folder"
						isSaving={isLoading === Status.Fetching}
						isEditButtonDisabled={_.isEqual(
							expenseFolderDraft,
							expenseFolderToEdit,
						)}
					/>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default EditExpenseFolderDialog;
