export const formatNumberWithSpaces = (num: number): string => {
	const [integerPart, decimalPart] = num.toFixed(2).split(".");

	const formattedIntegerPart = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		" ",
	);

	return decimalPart
		? `${formattedIntegerPart}.${decimalPart}`
		: formattedIntegerPart;
};
