import { Box, Tooltip, InputAdornment } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { Profile } from './profile';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import NumberInputField from '../../sharedComponents/NumberInputField';

interface CurrencyFieldProps {
	handleCreditCardFeeChange: (e: number) => void;
	profile: Profile;
}

export const CurrencyFeeField: React.FC<CurrencyFieldProps> = ({
	handleCreditCardFeeChange,
	profile,
}) => {
	const [isCurrencyFeeTooltipOpen, setIsCurrencyFeeTooltipOpen] =
		useState<boolean>(false);

	return (
		<Box display={'flex'} alignItems={'center'}>
			<Tooltip
				title={t('currency_fee_tooltip')}
				open={isCurrencyFeeTooltipOpen}
				onMouseLeave={() => setIsCurrencyFeeTooltipOpen(false)}
				onMouseOver={() => setIsCurrencyFeeTooltipOpen(true)}
			>
				<NumberInputField
					value={profile.creditCardFee ?? 0}
					onChange={handleCreditCardFeeChange}
					maxValue={4.5}
					label={t('currency_fee')}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">%</InputAdornment>
						),
					}}
				/>
			</Tooltip>
			<HelpOutlineRoundedIcon
				fontSize="small"
				sx={{ marginLeft: '0.4rem', marginTop: '0.4rem' }}
				onClick={() =>
					setIsCurrencyFeeTooltipOpen(!isCurrencyFeeTooltipOpen)
				}
				onMouseLeave={() => setIsCurrencyFeeTooltipOpen(false)}
			/>
		</Box>
	);
};
