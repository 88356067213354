import { Box, Tooltip, TextField, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { Profile } from './profile';

interface SendEmailFieldProps {
	handleSendToChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	profile: Profile;
}

export const SendEmailField: React.FC<SendEmailFieldProps> = ({
	handleSendToChange,
	profile,
}) => {
	const [isTooltipSendToOpen, setIsTooltipSendToOpen] = useState(false);
	const isOnPhone = useMediaQuery('(max-width:600px)');
	const { t } = useTranslation();

	return (
		<Box display={'flex'} alignItems={'center'}>
			<Tooltip
				title={t('receiving_email_tooltip')}
				open={isTooltipSendToOpen}
				onMouseLeave={() => setIsTooltipSendToOpen(false)}
				onMouseEnter={() => {
					if (!isOnPhone) setIsTooltipSendToOpen(true);
				}}
			>
				<TextField
					label={t('send_to')}
					value={profile.sendTo}
					onChange={handleSendToChange}
					type="email"
					required
				></TextField>
			</Tooltip>
			<HelpOutlineRoundedIcon
				fontSize="small"
				sx={{ marginLeft: '0.4rem', marginTop: '0.4rem' }}
				onClick={() => setIsTooltipSendToOpen(!isTooltipSendToOpen)}
			/>
		</Box>
	);
};
