import { ExpenseFolder } from "../../expenseFolders/models/expenseFolder";
import { Profile } from "../../profile/profile";
import { concatExpenses } from "../../expenseFolders/utils/concatExpenses";

export function shouldDisplayCreditCardFee(
	profile: Profile,
	folder: ExpenseFolder,
) {
	return (
		profile.creditCardFee &&
		concatExpenses(folder).filter(
			(e) => e.currencyCode !== profile.currencyCode,
		).length > 0
	);
}
