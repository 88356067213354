import {createAsyncThunk} from "@reduxjs/toolkit";
import {ExpenseFolder} from "../models/expenseFolder";
import {toExpenseFolder, toExpenseFolderDto} from "../../../utils/mappings";
import createApiClient from "../../../services/createApiClient";
import {RootState} from "../../../app/store";

export const getExpenseFolders = createAsyncThunk(
	"expenseFolders/fetchFolders",
	async (_, thunkApi) => {
		const profileId = (thunkApi.getState() as RootState).profileReducer
			.profile.id;
		const client = await createApiClient()
		const res = await client.expenseFolder.getByProfileIdDetail(
			profileId,
		);
		console.log(res);
		return res.data.map(toExpenseFolder);
	},
);

export const postExpenseFolder = createAsyncThunk(
	"expenseFolders/postFolder",
	async (folder: ExpenseFolder, thunkApi) => {
		const client = await createApiClient()
		const res = await client.expenseFolder.createCreate(
			toExpenseFolderDto(folder),
		);
		return toExpenseFolder(res.data);
	},
);

export const putExpenseFolder = createAsyncThunk(
	"expenseFolders/putFolder",
	async (folder: ExpenseFolder, thunkApi) => {

		const client = await createApiClient()
		await client.expenseFolder.updateUpdate(
			toExpenseFolderDto(folder),
		);
		return folder;
	},
);

export const deleteExpenseFolder = createAsyncThunk(
	"expenseFolders/deleteFolder",
	async (folderId: number) => {
		const client = await createApiClient()
		await client.expenseFolder.deleteDelete(folderId);
		return folderId;
	},
);
