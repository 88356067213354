import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ExpenseUnion } from "./expense";
import { Status } from "../expenseFoldersSlice/expenseFoldersSlice";

const initialState: UiState = {
	isAddExpenseDialogOpen: false,
	isEditExpenseFolderDialogOpen: false,
	isEditExpenseDialogOpen: false,
	isAddExpenseFolderDialogOpen: false,
	expenseFolderToEditId: null,
	expenseToEditId: null,
	selectedExpenseFolderId: null,
	idOfFolderToSend: null,
	isSendFolderDialogOpen: false,
	isFirstTimeLogin: false,
	emailSuccessFullySent: null,
	beganExpenses: [],
	exchangeRateStatus: Status.Idle,
};

export interface UiState {
	isAddExpenseDialogOpen: boolean;
	isEditExpenseDialogOpen: boolean;
	isEditExpenseFolderDialogOpen: boolean;
	expenseFolderToEditId: number | null;
	expenseToEditId: number | null;
	selectedExpenseFolderId: number | null;
	idOfFolderToSend: number | null;
	isSendFolderDialogOpen: boolean;
	isAddExpenseFolderDialogOpen: boolean;
	isFirstTimeLogin: boolean;
	emailSuccessFullySent: boolean | null;
	exchangeRateStatus: Status;
	beganExpenses: ExpenseUnion[];
}

export const expenseFoldersUiSlice = createSlice({
	name: "ui",
	initialState,
	reducers: {
		openAddExpenseDialog: (
			state,
			action: PayloadAction<{
				selectedExpenseFolderId: number;
			}>,
		) => {
			state.selectedExpenseFolderId =
				action.payload.selectedExpenseFolderId;
			state.isAddExpenseDialogOpen = true;
		},
		closeAddExpenseDialog: (state) => {
			state.isAddExpenseDialogOpen = false;
			state.expenseToEditId = null;
		},
		openEditExpenseDialog: (
			state,
			action: PayloadAction<{
				expenseToEditId: number;
			}>,
		) => {
			state.isEditExpenseDialogOpen = true;
			state.expenseToEditId = action.payload.expenseToEditId;
		},
		closeEditExpenseDialog: (state) => {
			state.isEditExpenseDialogOpen = false;
			state.expenseToEditId = null;
		},
		openEditFolderDialog: (state, action) => {
			state.isEditExpenseFolderDialogOpen = true;
			state.expenseFolderToEditId = action.payload;
		},
		closeEditFolderDialog: (state) => {
			state.isEditExpenseFolderDialogOpen = false;
			state.expenseFolderToEditId = null;
		},
		openAddFolderDialog: (state) => {
			state.isAddExpenseFolderDialogOpen = true;
		},
		closeAddFolderDialog: (state) => {
			state.isAddExpenseFolderDialogOpen = false;
		},
		openSendFolderDialog: (state, action) => {
			state.isSendFolderDialogOpen = true;
			state.idOfFolderToSend = action.payload;
		},
		closeSendFolderDialog: (state) => {
			state.isSendFolderDialogOpen = false;
			state.idOfFolderToSend = null;
		},
		setIsFirstTimeLogin: (state, action) => {
			state.isFirstTimeLogin = action.payload;
		},
		setEmailSuccessFullySent: (state, action) => {
			state.emailSuccessFullySent = action.payload;
		},
		removeBeganExpense: (state, action) => {
			state.beganExpenses = state.beganExpenses.filter(
				(expense) => expense.expenseReportId !== action.payload,
			);
		},
		setBeganExpense: (state, action) => {
			state.beganExpenses = state.beganExpenses.map((expense) => {
				if (expense.id === action.payload.id) {
					return action.payload;
				}
				return expense;
			});
		},
		addBeganExpense: (state, action) => {
			state.beganExpenses = [...state.beganExpenses, action.payload];
		},
	},
});

export const {
	openAddExpenseDialog,
	closeAddExpenseDialog,
	openEditFolderDialog,
	closeEditFolderDialog,
	openAddFolderDialog,
	openEditExpenseDialog,
	closeAddFolderDialog,
	closeEditExpenseDialog,
	openSendFolderDialog,
	closeSendFolderDialog,
	setIsFirstTimeLogin,
	setEmailSuccessFullySent,
	removeBeganExpense,
	setBeganExpense,
	addBeganExpense,
} = expenseFoldersUiSlice.actions;

export default expenseFoldersUiSlice.reducer;
