import React, {ChangeEvent, FC} from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup, Tooltip} from '@mui/material';
import {Profile} from './profile';
import {useTranslation} from 'react-i18next';

interface Props {
	setTransferOverFtp: (value: boolean) => void;
	profile: Profile;
}

export const EhfRadioButtons: FC<Props> = ({
											   setTransferOverFtp,
											   profile
										   }) => {
	const {t} = useTranslation();

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value as 'ehf' | 'email';
		setTransferOverFtp(newValue === 'ehf');
	};

	if (!profile) return null;

	const isDisabled = !profile.bankAccount;

	return (
		<Tooltip title={isDisabled ? t("must_have_bank_account_number") : ''}>
			<span>
        <FormControl component="fieldset">
          <RadioGroup
			  name="expenseDestination"
			  value={profile.transferOverFtp ? 'ehf' : 'email'}
			  onChange={handleChange}
		  >
            <FormControlLabel
				value="ehf"
				control={<Radio/>}
				label={t('send_pdf_as_ehf')}
				disabled={isDisabled}
			/>
            <FormControlLabel
				value="email"
				control={<Radio/>}
				label={t('send_pdf_email')}
				disabled={isDisabled}
			/>
          </RadioGroup>
        </FormControl>
      </span>
		</Tooltip>
	);
};
