import { t } from 'i18next';
import { ExpenseFolder } from '../models/expenseFolder';
import { openAddExpenseDialog } from '../models/expenseFoldersUiSlice';
import { concatExpenses } from '../utils/concatExpenses';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch } from '../../../app/hooks';
import ResizingButton from '../../../sharedComponents/ResizingButton';
//todo: move to expense folder
const AddExpenseButton: React.FC<{ folder: ExpenseFolder }> = ({ folder }) => {
	const dispatch = useAppDispatch();
	return (
		<ResizingButton
			variant="text"
			color="primary"
			disabled={concatExpenses(folder).length >= 40}
			onClick={() =>
				dispatch(
					openAddExpenseDialog({
						selectedExpenseFolderId: folder.id,
					}),
				)
			}
			sx={{
				marginLeft: '0rem',
				paddingLeft: '0rem',
				marginBottom: '0.7rem',
				marginTop: '0rem',
			}}
		>
			{concatExpenses(folder).length < 40
				? t('add_expense')
				: t('max_expenses')}
			<AddIcon />
		</ResizingButton>
	);
};

export default AddExpenseButton;
