import React, {useEffect, useState} from 'react';
import {Box, TextField, Typography} from '@mui/material';
import {Profile} from '../features/profile/profile';
import Loading from './Loading';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import {ProfileStatus, putProfile, setProfileStatus,} from '../features/profile/profileSlice';
import {setIsFirstTimeLogin} from '../features/expenseFolders/models/expenseFoldersUiSlice';
import {useTranslation} from 'react-i18next';
import {matomo} from '../app/MatomoTracker';

import createApiClient from '../services/createApiClient';
import AlertSnackbar from '../sharedComponents/AlertSnackbar';
import BackToHomeLink from '../sharedComponents/BackToHomeLink';
import CurrencyList from '../sharedComponents/CurrencyList';
import ResizingButton from '../sharedComponents/ResizingButton';
import {CurrencyFeeField} from '../features/profile/CurrencyFeeField';
import {EhfRadioButtons} from '../features/profile/EhfRadioButtons';
import {RateFields} from '../features/profile/RateFields';
import {SendEmailField} from '../features/profile/SendEmailField';

const ProfileComponent: React.FC = () => {
	const [profile, setProfile] = useState<Profile>();

	const p = useAppSelector((state) => state.profileReducer);


	const dispatch = useAppDispatch();

	const {t} = useTranslation();

	const [hasFtpAccess, setHasFtpAccess] = useState(false);

	useEffect(() => {
		dispatch(setIsFirstTimeLogin(false));
		setProfile({...p.profile});
		createApiClient().then((apiClient) =>
			apiClient.profile.getFtpAccessDetail(p.profile.id)
				.then((response) => {
					setHasFtpAccess(response.data.hasAccess!);
				}));
		dispatch(setProfileStatus(ProfileStatus.Idle));
	}, []);

	const handleCurrencyChange = (currencyCode: string) => {
		setProfile({
			...profile!,
			currencyCode: currencyCode,
		});
	};
	const handleSendToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setProfile({...profile!, sendTo: event.target.value});
	};
	const handleBankAccountChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setProfile({...profile!, bankAccount: event.target.value});
	};
	const handleRatePerKmChange = (ratePerKm: number) => {
		setProfile({
			...profile!,
			ratePerKm: ratePerKm || 0,
		});
	};
	const handleRatePerPassengerChange = (rate: number) => {
		setProfile({
			...profile!,
			ratePerPassenger: rate || 0,
		});
	};
	const handleCreditCardFeeChange = (creditCardFee: number) => {
		setProfile({
			...profile!,
			creditCardFee: creditCardFee,
		});
	};

	const handleTransferOverFtpChange = (value: boolean) => {
		setProfile({
			...profile!,
			transferOverFtp: value,
		});
	};

	if (profile === undefined) {
		return <Loading/>;
	}

	return (
		<Box>
			<BackToHomeLink/>
			{hasFtpAccess && (
				<EhfRadioButtons
					setTransferOverFtp={handleTransferOverFtpChange}
					profile={profile}
				/>
			)}

			<Typography variant="h3" component="h3">
				{t('settings')}
			</Typography>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					dispatch(putProfile(profile));
					matomo.trackEvent(
						'Settings',
						'click',
						'Settings saved',
						profile.id,
					);
				}}
			>
				<SendEmailField
					handleSendToChange={handleSendToChange}
					profile={profile}
				/>

				<CurrencyList
					handleCurrencyChange={handleCurrencyChange}
					value={profile.currencyCode}
					size="large"
				/>
				<TextField
					label={t('account_number')}
					onChange={handleBankAccountChange}
					value={profile.bankAccount}
					required={!!profile.transferOverFtp}
				></TextField>

				<RateFields
					profile={profile}
					handleRatePerKmChange={handleRatePerKmChange}
					handleRatePerPassengerChange={handleRatePerPassengerChange}
				/>
				<CurrencyFeeField
					handleCreditCardFeeChange={handleCreditCardFeeChange}
					profile={profile}
				/>

				<ResizingButton
					type="submit"
					disabled={p.status === ProfileStatus.Putting}
					style={{float: 'right', marginTop: '0.5rem'}}
				>
					{t('save')}
				</ResizingButton>
			</form>

			<AlertSnackbar
				open={p.status === ProfileStatus.SuccessPutting}
				onClose={() => dispatch(setProfileStatus(ProfileStatus.Idle))}
				textCode={'your_changes_have_been_saved'}
				severity={'success'}
			/>

			<AlertSnackbar
				open={p.status === ProfileStatus.FailedPutting}
				onClose={() => dispatch(setProfileStatus(ProfileStatus.Idle))}
				textCode={'error_updating_settings'}
				severity={'error'}
			/>
		</Box>
	);
};

export default ProfileComponent;
