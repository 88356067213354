import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {ExpenseFolderPdf} from "./expenseFolderPdf";
import {toExpenseFolderPdf} from "../../utils/mappings";
import createApiClient from "../../services/createApiClient";

export enum ExpenseFolderPdfStatus {
	Idle,
	Getting,
	Posting,
	FailedGetting,
	FailedPosting,
}

const initialState: ExpenseFolderPdfState = {
	pdfsExpense: [],
	status: ExpenseFolderPdfStatus.Idle,
	pdfsOld: [],
};

export interface ExpenseFolderPdfState {
	pdfsExpense: ExpenseFolderPdf[];
	pdfsOld: ExpenseFolderPdf[];
	status: ExpenseFolderPdfStatus;
}

export const getExpenseFolderPdfs = createAsyncThunk(
	"pdf/fetchPdfs",
	async (_, thunkApi) => {

		const profile = (thunkApi.getState() as RootState).profileReducer
			.profile;
		const apiClient = await createApiClient();
		const res = await apiClient.expenseFolderPdf.getByProfileIdDetail(
			profile.id,
		);
		return [...res.data].map(toExpenseFolderPdf);
	},
);

export const getExpenseFolderPdfsTravelExpense = createAsyncThunk(
	"pdf/fetchPdfsOld",
	async (_, thunkApi) => {

		const profile = (thunkApi.getState() as RootState).profileReducer
			.profile;
		const client = await createApiClient(
		)
		const res = await client.travelExpense.getExpenseFolderPdfsDetail(profile.tacNo);
		return res.data.map(toExpenseFolderPdf);
	},
);

export const expenseFolderPdfsSlice = createSlice({
	name: "pdf",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getExpenseFolderPdfs.pending, (state) => {
				state.status = ExpenseFolderPdfStatus.Getting;
			})
			.addCase(getExpenseFolderPdfs.fulfilled, (state, action) => {
				state.status = ExpenseFolderPdfStatus.Idle;
				state.pdfsExpense = action.payload;
			})
			.addCase(getExpenseFolderPdfs.rejected, (state) => {
				state.status = ExpenseFolderPdfStatus.FailedGetting;
			})
			.addCase(getExpenseFolderPdfsTravelExpense.pending, (state) => {
				state.status = ExpenseFolderPdfStatus.Getting;
			})
			.addCase(
				getExpenseFolderPdfsTravelExpense.fulfilled,
				(state, action) => {
					state.status = ExpenseFolderPdfStatus.Idle;
					state.pdfsOld = action.payload;
				},
			)
			.addCase(getExpenseFolderPdfsTravelExpense.rejected, (state) => {
				state.status = ExpenseFolderPdfStatus.FailedGetting;
			});
	},
});

export default expenseFolderPdfsSlice.reducer;
