import {useState} from 'react';
import {Box} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {postBaseExpense, postFlight,} from '../expenseFolders/expenseFoldersSlice/expenseThunks';
import {closeAddExpenseDialog} from '../expenseFolders/models/expenseFoldersUiSlice';
import {useTranslation} from 'react-i18next';
import BhTripsTable from './BhTripsTable';
import {
	Api,
	BergHansenM3ClientModelsWebgateGetOrdersOrder,
	BergHansenModelsExpenseBhExpense,
	BergHansenModelsExpenseM3ExpenseName as M3ExpenseName,
} from '../../services/swaggerClient';
import {toBaseExpenseBhExpense, toFlightFromBhExpense,} from '../../utils/mappings';
import {BhOrdersStatus, getBhOrders, processOrder,} from './bhExpensesSlice';
import {LoadingButton} from '@mui/lab';
import ResizingButton from '../../sharedComponents/ResizingButton';
import createApiClient from "../../services/createApiClient";

const BhTrips = () => {
	const [checked, setChecked] = useState<number[]>([]);
	const [isAddingExpenses, setIsAddingExpenses] = useState(false);

	const dispatch = useAppDispatch();

	const bhExpensesReducer = useAppSelector(
		(state) => state.bhExpensesReducer,
	);

	const profile = useAppSelector((state) => state.profileReducer.profile);

	const selectedExpenseFolderId = useAppSelector(
		(state) => state.uiReducer.selectedExpenseFolderId,
	);

	const closeDialog = () => {
		dispatch(closeAddExpenseDialog());
	};

	const {t} = useTranslation();

	const handleExpense = (
		expense: BergHansenModelsExpenseBhExpense,
		order: BergHansenM3ClientModelsWebgateGetOrdersOrder,
		selectedExpenseFolderId: number,
	) => {
		if (
			expense.type === M3ExpenseName.AirportTrain ||
			expense.type === M3ExpenseName.Hotel ||
			expense.type === M3ExpenseName.Taxi ||
			expense.type === M3ExpenseName.Other
		) {
			return dispatch(
				postBaseExpense(
					toBaseExpenseBhExpense(expense, selectedExpenseFolderId),
				),
			);
		} else if (expense.type === M3ExpenseName.Flight) {
			return dispatch(
				postFlight(
					toFlightFromBhExpense(
						expense,
						selectedExpenseFolderId,
						order.originDisp ?? '',
						order.destDisp ?? '',
					),
				),
			);
		}
		// If none of the above, no action needed
		return Promise.resolve();
	};

	const fetchExpensesForOrder = async (
		order: BergHansenM3ClientModelsWebgateGetOrdersOrder, apiClient:Api<unknown>
	) => {

		const expenses = await apiClient.bhExpense.getExpensesOnOrderDetail(
			parseInt(order.orderNo!),
		);
		await Promise.all(
			expenses.data.map((expense) =>
				handleExpense(expense, order, selectedExpenseFolderId!),
			),
		);
		await dispatch(processOrder(parseInt(order.orderNo!))).unwrap();
	};

	const postBhExpenses = async () => {
		const filteredOrders = bhExpensesReducer.orders.filter((order) =>
			checked.includes(parseInt(order.orderNo!)),
		);
		const apiClient = await createApiClient();
		const promises = filteredOrders.map((order) =>
			fetchExpensesForOrder(order, apiClient),
		);
		return Promise.all(promises);
	};

	return (
		<Box>
			<BhTripsTable
				bergHansenExpenses={bhExpensesReducer.orders}
				handleToggle={(value: number) => {
					setChecked([...checked, value]);
				}}
			/>

			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				marginTop={'1rem'}
			>
				<LoadingButton
					onClick={() =>
						dispatch(
							getBhOrders({
								orderCount: bhExpensesReducer.orders.length + 6,
								profileId: profile.id,
							}),
						)
					}
					variant="text"
					color="primary"
					loading={
						bhExpensesReducer.status === BhOrdersStatus.Getting
					}
				>
					{t('load_more_orders')}
				</LoadingButton>
				<Box>
					<ResizingButton
						variant="text"
						onClick={closeDialog}
						style={{
							color: 'rgb(97, 97, 97)',
							fontWeight: 500,
						}}
					>
						{t('cancel')}
					</ResizingButton>
					<LoadingButton
						onClick={async () => {
							setIsAddingExpenses(true);
							postBhExpenses().then(() => {
								closeDialog();
								setIsAddingExpenses(false);
							});
						}}
						variant="contained"
						color="primary"
						loading={isAddingExpenses}
					>
						{t('add_expense')}
					</LoadingButton>
				</Box>
			</Box>
		</Box>
	);
};

export default BhTrips;
