import { ExpenseFolder } from "../models/expenseFolder";

export const InitExpenseFolder = (profileId: number): ExpenseFolder => {
	return {
		description: "",
		profileId: profileId,
		id: 0,
		otherTransports: [],
		baseExpenses: [],
		representations: [],
		mileageAllowances: [],
		flight: [],
		createdAt: new Date().toISOString(),
	};
};
