import { ExpenseUnion } from "../models/expense";
import { ExpenseFolder } from "../models/expenseFolder";

export const concatExpenses = (
	expenseFolder: ExpenseFolder,
): ExpenseUnion[] => {
	return (
		expenseFolder.baseExpenses
			?.concat(expenseFolder.flight ?? [])
			.concat(expenseFolder.mileageAllowances ?? [])
			.concat(expenseFolder.representations ?? [])
			.concat(expenseFolder.otherTransports ?? []) ?? []
	);
};
