import {User} from "@auth0/auth0-react";
import {
	BergHansenModelsExpenseAttachment,
	BergHansenModelsExpenseBaseExpense,
	BergHansenModelsExpenseBhExpense,
	BergHansenModelsExpenseCurrency,
	BergHansenModelsExpenseExpenseFolder,
	BergHansenModelsExpenseExpenseName,
	BergHansenModelsExpenseFlight,
	BergHansenModelsExpenseM3ExpenseName,
	BergHansenModelsExpenseMileageAllowance,
	BergHansenModelsExpenseOtherTransport,
	BergHansenModelsExpenseProfile,
	BergHansenModelsExpenseRepresentation,
	ExpenseExpenseFolderPdf,
} from "../services/swaggerClient";
import {Attachment} from "../features/expenseFolders/models/attachments";
import {
	BaseExpense,
	ExpenseName,
	ExpenseUnion,
	Flight,
	MileageAllowance,
	OtherTransport,
	Representation,
} from "../features/expenseFolders/models/expense";
import {ExpenseFolder} from "../features/expenseFolders/models/expenseFolder";
import {ExpenseFolderPdf} from "../features/expenseFolderPdfs/expenseFolderPdf";
import {Profile} from "../features/profile/profile";
import {Currency} from "../features/currency/currency";

type ExpenseDto =
	| BergHansenModelsExpenseFlight
	| BergHansenModelsExpenseBaseExpense
	| BergHansenModelsExpenseRepresentation
	| BergHansenModelsExpenseMileageAllowance
	| BergHansenModelsExpenseOtherTransport;

export const toAttachment = (
	attachment: BergHansenModelsExpenseAttachment,
): Attachment => {
	return {
		...attachment,
	};
};

export const toExpenseName = (
	expenseName: BergHansenModelsExpenseExpenseName,
): ExpenseName => {
	return ExpenseName[expenseName.toString() as keyof typeof ExpenseName];
};

export const toExpenseNameDto = (
	expenseName: ExpenseName,
): BergHansenModelsExpenseExpenseName => {
	return BergHansenModelsExpenseExpenseName[
		expenseName.toString() as keyof typeof ExpenseName
		];
};

export const toExpense = (expense: ExpenseDto): ExpenseUnion => {
	return {
		...expense,
		expenseName: toExpenseName(expense.expenseName),
	};
};

const toExpenseFromBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseReportId: number,
) => {
	return {
		id: 0,
		expenseDate: bhExpense.date!,
		m3OrderNumber: bhExpense.orderNumber,
		description: bhExpense.description ?? " ",
		currencyCode: bhExpense.currency ?? "NOK",
		amount: bhExpense.amount ?? 0,
		expenseReportId: expenseReportId,
		attachments: bhExpense.url
			? [
				{
					expenseId: 0,
					id: 0,
					description:
						(bhExpense.description ?? "receipt") + ".pdf",
					mediaType: "pdf",
					data: bhExpense.data!,
					url: bhExpense.url,
				},
			]
			: [],
		expenseName: toExpenseNameFromBhExpense(bhExpense.type!),
		createdAt: new Date().toISOString(),
	};
};

export const toFlightFromBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseFolderId: number,
	startLocation: string,
	endLocation: string,
): Flight => ({
	...toExpenseFromBhExpense(bhExpense, expenseFolderId),
	startLocation: startLocation,
	endLocation: endLocation,
});

export const toBaseExpenseBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseFolderId: number,
): BaseExpense => ({
	...toExpenseFromBhExpense(bhExpense, expenseFolderId),
	description: bhExpense.description ?? bhExpense.type!.toString(),
});

export const toOtherTransportBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseFolderId: number,
	startLocation: string,
	endLocation: string,
): OtherTransport => ({
	...toExpenseFromBhExpense(bhExpense, expenseFolderId),
	startLocation: startLocation,
	endLocation: endLocation,
	description: bhExpense.description ?? bhExpense.type!.toString(),
});

export const toOtherTransport = (
	otherTransport: BergHansenModelsExpenseOtherTransport,
): OtherTransport => ({
	...otherTransport,
	expenseName: toExpenseName(otherTransport.expenseName),
});

export const toBaseExpense = (
	expense: BergHansenModelsExpenseBaseExpense,
): BaseExpense => ({
	...expense,
	expenseName: toExpenseName(expense.expenseName),
});

export const toRepresentation = (
	representation: BergHansenModelsExpenseRepresentation,
): Representation => ({
	...representation,
	expenseName: toExpenseName(representation.expenseName),
});

export const toMileageAllowance = (
	mileageAllowance: BergHansenModelsExpenseMileageAllowance,
): MileageAllowance => ({
	...mileageAllowance,
	expenseName: toExpenseName(mileageAllowance.expenseName),
});

export const toFlight = (flight: BergHansenModelsExpenseFlight): Flight => ({
	...flight,
	expenseName: toExpenseName(flight.expenseName),
});

export const toExpenseFolder = (
	expenseFolder: BergHansenModelsExpenseExpenseFolder,
): ExpenseFolder => {
	return {
		...expenseFolder,
		flight: expenseFolder.flight?.map(toFlight) ?? [],
		representations:
			expenseFolder.representations?.map(toRepresentation) ?? [],
		mileageAllowances:
			expenseFolder.mileageAllowances?.map(toMileageAllowance) ?? [],
		baseExpenses: expenseFolder.baseExpense?.map(toBaseExpense) ?? [],
		otherTransports:
			expenseFolder.otherTransports?.map(toOtherTransport) ?? [],
	};
};

export const toExpenseFolderPdf = (
	pdf: ExpenseExpenseFolderPdf,
): ExpenseFolderPdf => {
	return {
		...pdf,
		source: pdf.source,
	};
};

export const toProfile = (profile: BergHansenModelsExpenseProfile): Profile => {
	console.log(profile);
	return {
		...profile,
	};
};

export const toAttachmentDto = (
	attachment: Attachment,
): BergHansenModelsExpenseAttachment => ({
	...attachment,
});

export const toRepresentationDto = (
	representation: Representation,
): BergHansenModelsExpenseRepresentation => {
	return {
		...representation,
		expenseName: toExpenseNameDto(representation.expenseName),
	};
};

export const toExpenseDto = (expense: ExpenseUnion) => {
	return {
		...expense,
		expenseName: toExpenseNameDto(expense.expenseName),
	};
};

export const toFlightDto = (expense: Flight): BergHansenModelsExpenseFlight =>
	toExpenseDto(expense) as BergHansenModelsExpenseFlight;

export const toMileageAllowanceDto = (
	mileageAllowance: MileageAllowance,
): BergHansenModelsExpenseMileageAllowance =>
	toExpenseDto(mileageAllowance) as BergHansenModelsExpenseMileageAllowance;

export const toBaseExpenseDto = (
	baseExpense: BaseExpense,
): BergHansenModelsExpenseBaseExpense => toExpenseDto(baseExpense);

export const toOtherTransportDto = (
	otherTransport: OtherTransport,
): BergHansenModelsExpenseOtherTransport =>
	toExpenseDto(otherTransport) as BergHansenModelsExpenseOtherTransport;

export const toExpenseFolderDto = (
	expenseFolder: ExpenseFolder,
): BergHansenModelsExpenseExpenseFolder => ({
	...expenseFolder,
	flight: expenseFolder.flight?.map(toFlightDto),
	representations: expenseFolder.representations?.map(toRepresentationDto),
	totalAmount: 0,
	mileageAllowances: expenseFolder.mileageAllowances?.map(
		toMileageAllowanceDto,
	),
	baseExpense: expenseFolder.baseExpenses?.map(toBaseExpenseDto),
	otherTransports: expenseFolder.otherTransports?.map(toOtherTransportDto),
	currencyCode: "NOK",
});

export const toExpenseFolderPdfDto = (
	pdf: ExpenseFolderPdf,
): ExpenseExpenseFolderPdf => ({
	...pdf,
	amount: parseFloat(pdf.amount.toString()),
});

export const toProfileDto = (
	profile: Profile,
): BergHansenModelsExpenseProfile => ({
	...profile,
});

export const toProfileDtoFromUser = (
	user: User,
): BergHansenModelsExpenseProfile => {
	return {
		id: 0,
		email: user.email,
		currencyCode: "NOK",
		ratePerKm: 4.9,
		ratePerPassenger: 1,
		creditCardFee: 0,
		expenseFolders: [],
		expenseFolderPdfs: [],
		languageCode: "",
		bankAccount: "",
		tacNo: user.TacNo!,
		firstName: "",
		lastName: "",
		transferOverFtp: false,
	};
};

export const toExpenseNameFromBhExpense = (
	expenseName: BergHansenModelsExpenseM3ExpenseName,
): ExpenseName => {
	if (expenseName === BergHansenModelsExpenseM3ExpenseName.AirportTrain)
		return ExpenseName.Train;
	if (expenseName === BergHansenModelsExpenseM3ExpenseName.Flight)
		return ExpenseName.Flight;
	if (expenseName === BergHansenModelsExpenseM3ExpenseName.Hotel)
		return ExpenseName.Hotel;
	if (expenseName === BergHansenModelsExpenseM3ExpenseName.Taxi)
		return ExpenseName.OtherTransport;
	return ExpenseName.Other;
};

export const toCurrency = (
	currencyDto: BergHansenModelsExpenseCurrency,
): Currency => ({
	name: currencyDto.name,
	code: currencyDto.code,
});
