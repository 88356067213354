import {
	Dialog,
	DialogContent,
	DialogTitle,
	useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { closeEditExpenseDialog } from '../models/expenseFoldersUiSlice';

import EditExpenseForm from './EditExpenseForm';
import CloseIconButtonComponent from '../../../sharedComponents/CloseIconButtonComponent';

const EditExpenseDialog = () => {
	const open = useAppSelector(
		(state) => state.uiReducer.isEditExpenseDialogOpen,
	);
	const isOnMobile = useMediaQuery('(max-width:600px)');
	const dispatch = useAppDispatch();
	const closeDialog = () => {
		dispatch(closeEditExpenseDialog());
	};
	const { t } = useTranslation();
	return (
		<Dialog open={open} onClose={closeDialog} fullScreen={isOnMobile}>
			<DialogTitle>{t('edit_receipt')}</DialogTitle>
			<DialogContent>
				<EditExpenseForm />
			</DialogContent>
			<CloseIconButtonComponent onClick={closeDialog} />
		</Dialog>
	);
};

export default EditExpenseDialog;
