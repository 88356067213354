/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BergHansenBootstrapperModelsMonitor {
  ok?: boolean;
  message?: string | null;
}

export interface BergHansenM3ClientModelsWebgateGetOrdersOrder {
  travellers?: BergHansenM3ClientModelsWebgateGetOrdersOrderTraveller[] | null;
  orderNo?: string | null;
  gdsPnr?: string | null;
  origin?: string | null;
  originDisp?: string | null;
  dest?: string | null;
  destDisp?: string | null;
  outward?: string | null;
  air?: boolean;
  car?: boolean;
  hotel?: boolean;
  rail?: boolean;
}

export interface BergHansenM3ClientModelsWebgateGetOrdersOrderTraveller {
  psgrFirstName?: string | null;
  psgrLastName?: string | null;
  tacNo?: string | null;
  email?: string | null;
  mobile?: string | null;
  search?: string | null;
  receipt?: boolean;
}

export interface BergHansenModelsCurrencyExchange {
  sourceCurrency?: string | null;
  targetCurrency?: string | null;
  /** @format double */
  valueInTargetCurrency?: number;
}

export interface BergHansenModelsExpenseAttachment {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  description: string;
  /** @format int32 */
  expenseId: number;
  data?: string | null;
  /** @minLength 1 */
  mediaType: string;
  url?: string | null;
}

export interface BergHansenModelsExpenseBaseExpense {
  /** @format int32 */
  id: number;
  /** @format double */
  amount: number;
  /** @minLength 1 */
  currencyCode: string;
  /** @format int32 */
  expenseReportId: number;
  expenseName: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate: string;
  /** @format date-time */
  createdAt: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  exchangeDetails?: BergHansenModelsExpenseExchangeDetails;
  description?: string | null;
}

export interface BergHansenModelsExpenseBhExpense {
  /** @minLength 1 */
  expenseId: string;
  type?: BergHansenModelsExpenseM3ExpenseName;
  description?: string | null;
  /** @format date-time */
  date?: string;
  /** @format double */
  amount: number;
  url?: string | null;
  data?: string | null;
  /** @minLength 1 */
  currency: string;
  /** @format int32 */
  orderNumber: number;
}

export interface BergHansenModelsExpenseCurrency {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  code: string;
}

export interface BergHansenModelsExpenseExchangeDetails {
  /** @format double */
  amountInTargetCurrency: number;
  /** @format double */
  exchangeRate: number;
  /** @minLength 1 */
  targetCurrency: string;
}

export interface BergHansenModelsExpenseExpenseFolder {
  /** @format int32 */
  id: number;
  description?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  userSetStartDate?: string | null;
  /** @format date-time */
  userSetEndDate?: string | null;
  /** @format date-time */
  submitDate?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format double */
  totalAmount: number;
  /**
   * @minLength 0
   * @maxLength 5
   */
  currencyCode: string;
  /** @format int32 */
  profileId: number;
  representations?: BergHansenModelsExpenseRepresentation[] | null;
  mileageAllowances?: BergHansenModelsExpenseMileageAllowance[] | null;
  flight?: BergHansenModelsExpenseFlight[] | null;
  baseExpense?: BergHansenModelsExpenseBaseExpense[] | null;
  otherTransports?: BergHansenModelsExpenseOtherTransport[] | null;
  status?: BergHansenModelsExpenseExpenseReportStatus;
}

export interface BergHansenModelsExpenseExpenseFolderPdf {
  /** @format int32 */
  id: number;
  /** @format int32 */
  profileId: number;
  /** @minLength 1 */
  data: string;
  description?: string | null;
  /** @format double */
  amount: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format date-time */
  createdAt?: string;
  /**
   * @minLength 0
   * @maxLength 5
   */
  currencyCode: string;
}

export enum BergHansenModelsExpenseExpenseName {
  Other = "Other",
  OtherTransport = "OtherTransport",
  RentalCar = "RentalCar",
  MileageAllowance = "MileageAllowance",
  Flight = "Flight",
  Train = "Train",
  Meal = "Meal",
  Representation = "Representation",
  Hotel = "Hotel",
  Parking = "Parking",
}

export enum BergHansenModelsExpenseExpenseReportStatus {
  NotSubmitted = "NotSubmitted",
  Submitted = "Submitted",
}

export interface BergHansenModelsExpenseFlight {
  /** @format int32 */
  id: number;
  /** @format double */
  amount: number;
  /** @minLength 1 */
  currencyCode: string;
  /** @format int32 */
  expenseReportId: number;
  expenseName: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate: string;
  /** @format date-time */
  createdAt: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  exchangeDetails?: BergHansenModelsExpenseExchangeDetails;
  /** @minLength 1 */
  startLocation: string;
  /** @minLength 1 */
  endLocation: string;
}

export enum BergHansenModelsExpenseM3ExpenseName {
  Flight = "Flight",
  Hotel = "Hotel",
  Other = "Other",
  Taxi = "Taxi",
  AirportTrain = "AirportTrain",
}

export interface BergHansenModelsExpenseMileageAllowance {
  /** @format int32 */
  id: number;
  /** @format double */
  amount: number;
  /** @minLength 1 */
  currencyCode: string;
  /** @format int32 */
  expenseReportId: number;
  expenseName: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate: string;
  /** @format date-time */
  createdAt: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  exchangeDetails?: BergHansenModelsExpenseExchangeDetails;
  /** @format double */
  distanceInKm: number;
  /** @format double */
  passengerCount: number;
  /** @minLength 1 */
  startLocation: string;
  /** @minLength 1 */
  endLocation: string;
}

export interface BergHansenModelsExpenseOtherTransport {
  /** @format int32 */
  id: number;
  /** @format double */
  amount: number;
  /** @minLength 1 */
  currencyCode: string;
  /** @format int32 */
  expenseReportId: number;
  expenseName: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate: string;
  /** @format date-time */
  createdAt: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  exchangeDetails?: BergHansenModelsExpenseExchangeDetails;
  description?: string | null;
  /** @minLength 1 */
  startLocation: string;
  /** @minLength 1 */
  endLocation: string;
}

export interface BergHansenModelsExpenseProfile {
  /** @format int32 */
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  /** @format int32 */
  tacNo: number;
  sendTo?: string | null;
  email?: string | null;
  /** @format double */
  ratePerKm: number;
  /** @format double */
  ratePerPassenger: number;
  bankAccount?: string | null;
  /** @minLength 1 */
  currencyCode: string;
  languageCode?: string | null;
  /** @format double */
  creditCardFee: number;
  transferOverFtp?: boolean | null;
  expenseFolders?: BergHansenModelsExpenseExpenseFolder[] | null;
  expenseFolderPdfs?: BergHansenModelsExpenseExpenseFolderPdf[] | null;
}

export interface BergHansenModelsExpenseRepresentation {
  /** @format int32 */
  id: number;
  /** @format double */
  amount: number;
  /** @minLength 1 */
  currencyCode: string;
  /** @format int32 */
  expenseReportId: number;
  expenseName: BergHansenModelsExpenseExpenseName;
  /** @format date-time */
  expenseDate: string;
  /** @format date-time */
  createdAt: string;
  attachments?: BergHansenModelsExpenseAttachment[] | null;
  /** @format int32 */
  m3OrderNumber?: number | null;
  exchangeDetails?: BergHansenModelsExpenseExchangeDetails;
  /** @minLength 1 */
  who: string;
  /** @minLength 1 */
  where: string;
}

export interface BergHansenModelsExpenseSubmitDetails {
  expenseFolder: BergHansenModelsExpenseExpenseFolder;
  pdf: BergHansenModelsExpenseExpenseFolderPdf;
  /** @minLength 1 */
  sendTo: string;
}

export interface ExpenseExpenseFolderPdf {
  /** @format int32 */
  id: number;
  /** @format int32 */
  profileId: number;
  /** @minLength 1 */
  data: string;
  description?: string | null;
  /** @format double */
  amount: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format date-time */
  createdAt?: string;
  /**
   * @minLength 0
   * @maxLength 5
   */
  currencyCode: string;
  source: ExpenseSource;
}

export interface ExpenseModelsFtpAccess {
  hasAccess?: boolean;
}

export enum ExpenseSource {
  TravelExpense = "TravelExpense",
  Expense = "Expense",
}

export interface ExpenseUtilsExpenseUtilsAttachmentDataValid {
  isValid?: boolean;
}

export interface MicrosoftAspNetCoreMvcProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/Expense";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Expense
 * @version v1
 * @baseUrl /Expense
 * @contact Berg-Hansen Reisebureau AS (https://dev.azure.com/berg-hansen/Berg-Hansen%20Developer)
 *
 * Through this API you can view and update expense data
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  attachment = {
    /**
     * No description
     *
     * @tags Attachment
     * @name GetAttachment
     * @request GET:/Attachment/Get/{id}
     * @secure
     */
    getAttachment: (id: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseAttachment, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Attachment/Get/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachment
     * @name CreateCreate
     * @request POST:/Attachment/Create
     * @secure
     */
    createCreate: (data: BergHansenModelsExpenseAttachment, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseAttachment, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Attachment/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachment
     * @name ValidateCreate
     * @request POST:/Attachment/Validate
     * @secure
     */
    validateCreate: (data: BergHansenModelsExpenseAttachment, params: RequestParams = {}) =>
      this.request<ExpenseUtilsExpenseUtilsAttachmentDataValid, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Attachment/Validate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachment
     * @name DeleteDelete
     * @request DELETE:/Attachment/Delete/{id}
     * @secure
     */
    deleteDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Attachment/Delete/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  baseExpense = {
    /**
     * No description
     *
     * @tags BaseExpense
     * @name CreateCreate
     * @request POST:/BaseExpense/Create
     * @secure
     */
    createCreate: (data: BergHansenModelsExpenseBaseExpense, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseBaseExpense, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/BaseExpense/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BaseExpense
     * @name UpdateUpdate
     * @request PUT:/BaseExpense/Update
     * @secure
     */
    updateUpdate: (data: BergHansenModelsExpenseBaseExpense, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/BaseExpense/Update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  bhExpense = {
    /**
     * No description
     *
     * @tags BhExpense
     * @name GetExpensesOnOrderDetail
     * @request GET:/BhExpense/GetExpensesOnOrder/{orderNo}
     * @secure
     */
    getExpensesOnOrderDetail: (orderNo: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseBhExpense[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/BhExpense/GetExpensesOnOrder/${orderNo}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BhExpense
     * @name AddProcessedOrderCreate
     * @request POST:/BhExpense/AddProcessedOrder/{orderId}
     * @secure
     */
    addProcessedOrderCreate: (orderId: number, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/BhExpense/AddProcessedOrder/${orderId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BhExpense
     * @name GetNonProcessedOrderDetail
     * @request GET:/BhExpense/GetNonProcessedOrder/{profileId}
     * @secure
     */
    getNonProcessedOrderDetail: (
      profileId: number,
      query?: {
        /** @format int32 */
        orderCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BergHansenM3ClientModelsWebgateGetOrdersOrder[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/BhExpense/GetNonProcessedOrder/${profileId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BhExpense
     * @name GetOrderDetail
     * @request GET:/BhExpense/GetOrder/{profileId}
     * @secure
     */
    getOrderDetail: (
      profileId: number,
      query?: {
        /** @format int32 */
        orderNo?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BergHansenM3ClientModelsWebgateGetOrdersOrder, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/BhExpense/GetOrder/${profileId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  currency = {
    /**
     * No description
     *
     * @tags Currency
     * @name GetCurrenciesList
     * @request GET:/Currency/GetCurrencies
     * @secure
     */
    getCurrenciesList: (params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseCurrency[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Currency/GetCurrencies`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Currency
     * @name ExchangeList
     * @request GET:/Currency/Exchange
     * @secure
     */
    exchangeList: (
      query?: {
        sourceCurrencyCode?: string;
        targetCurrencyCode?: string;
        /** @format double */
        amount?: number;
        /** @format date-time */
        date?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BergHansenModelsCurrencyExchange, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Currency/Exchange`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  expense = {
    /**
     * No description
     *
     * @tags Expense
     * @name DeleteDelete
     * @request DELETE:/Expense/Delete/{id}
     * @secure
     */
    deleteDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Expense/Delete/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  expenseFolder = {
    /**
     * No description
     *
     * @tags ExpenseFolder
     * @name GetExpenseFolder
     * @request GET:/ExpenseFolder/Get/{id}
     * @secure
     */
    getExpenseFolder: (id: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseExpenseFolder, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/ExpenseFolder/Get/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExpenseFolder
     * @name GetByProfileIdDetail
     * @request GET:/ExpenseFolder/GetByProfileId/{profileId}
     * @secure
     */
    getByProfileIdDetail: (profileId: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseExpenseFolder[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/ExpenseFolder/GetByProfileId/${profileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExpenseFolder
     * @name CreateCreate
     * @request POST:/ExpenseFolder/Create
     * @secure
     */
    createCreate: (data: BergHansenModelsExpenseExpenseFolder, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseExpenseFolder, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/ExpenseFolder/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExpenseFolder
     * @name UpdateUpdate
     * @request PUT:/ExpenseFolder/Update
     * @secure
     */
    updateUpdate: (data: BergHansenModelsExpenseExpenseFolder, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseExpenseFolder, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/ExpenseFolder/Update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExpenseFolder
     * @name DeleteDelete
     * @request DELETE:/ExpenseFolder/Delete/{id}
     * @secure
     */
    deleteDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/ExpenseFolder/Delete/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  expenseFolderPdf = {
    /**
     * No description
     *
     * @tags ExpenseFolderPdf
     * @name GetByProfileIdDetail
     * @request GET:/ExpenseFolderPdf/GetByProfileId/{profileId}
     * @secure
     */
    getByProfileIdDetail: (profileId: number, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/ExpenseFolderPdf/GetByProfileId/${profileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExpenseFolderPdf
     * @name GetExpenseFolderPdf
     * @request GET:/ExpenseFolderPdf/Get/{pdfId}
     * @secure
     */
    getExpenseFolderPdf: (pdfId: number, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/ExpenseFolderPdf/Get/${pdfId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExpenseFolderPdf
     * @name SubmitCreate
     * @request POST:/ExpenseFolderPdf/Submit
     * @secure
     */
    submitCreate: (data: BergHansenModelsExpenseSubmitDetails, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/ExpenseFolderPdf/Submit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  flight = {
    /**
     * No description
     *
     * @tags Flight
     * @name CreateCreate
     * @request POST:/Flight/Create
     * @secure
     */
    createCreate: (data: BergHansenModelsExpenseFlight, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseFlight, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Flight/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Flight
     * @name UpdateUpdate
     * @request PUT:/Flight/Update
     * @secure
     */
    updateUpdate: (data: BergHansenModelsExpenseFlight, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Flight/Update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  mileageAllowance = {
    /**
     * No description
     *
     * @tags MileageAllowance
     * @name CreateCreate
     * @request POST:/MileageAllowance/Create
     * @secure
     */
    createCreate: (data: BergHansenModelsExpenseMileageAllowance, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseMileageAllowance, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/MileageAllowance/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MileageAllowance
     * @name UpdateUpdate
     * @request PUT:/MileageAllowance/Update
     * @secure
     */
    updateUpdate: (data: BergHansenModelsExpenseMileageAllowance, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseMileageAllowance, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/MileageAllowance/Update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  monitor = {
    /**
     * No description
     *
     * @tags Monitor
     * @name MonitorList
     * @request GET:/Monitor
     * @secure
     */
    monitorList: (params: RequestParams = {}) =>
      this.request<BergHansenBootstrapperModelsMonitor, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Monitor`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  otherTransport = {
    /**
     * No description
     *
     * @tags OtherTransport
     * @name CreateCreate
     * @request POST:/OtherTransport/Create
     * @secure
     */
    createCreate: (data: BergHansenModelsExpenseOtherTransport, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseOtherTransport, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/OtherTransport/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OtherTransport
     * @name UpdateUpdate
     * @request PUT:/OtherTransport/Update
     * @secure
     */
    updateUpdate: (
      data: BergHansenModelsExpenseOtherTransport,
      query?: {
        /** @format int32 */
        id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/OtherTransport/Update`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  profile = {
    /**
     * No description
     *
     * @tags Profile
     * @name GetProfile
     * @request GET:/Profile/Get/{id}
     * @secure
     */
    getProfile: (id: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseProfile, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Profile/Get/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name GetByTacNoDetail
     * @request GET:/Profile/GetByTacNo/{tacNo}
     * @secure
     */
    getByTacNoDetail: (tacNo: number, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseProfile, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Profile/GetByTacNo/${tacNo}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name CreateCreate
     * @request POST:/Profile/Create
     * @secure
     */
    createCreate: (data: BergHansenModelsExpenseProfile, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseProfile, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Profile/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name UpdateUpdate
     * @request PUT:/Profile/Update
     * @secure
     */
    updateUpdate: (data: BergHansenModelsExpenseProfile, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Profile/Update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name GetFtpAccessDetail
     * @request GET:/Profile/GetFtpAccess/{profileId}
     * @secure
     */
    getFtpAccessDetail: (profileId: number, params: RequestParams = {}) =>
      this.request<ExpenseModelsFtpAccess, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Profile/GetFtpAccess/${profileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  representation = {
    /**
     * No description
     *
     * @tags Representation
     * @name CreateCreate
     * @request POST:/Representation/Create
     * @secure
     */
    createCreate: (data: BergHansenModelsExpenseRepresentation, params: RequestParams = {}) =>
      this.request<BergHansenModelsExpenseRepresentation, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Representation/Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Representation
     * @name UpdateUpdate
     * @request PUT:/Representation/Update
     * @secure
     */
    updateUpdate: (data: BergHansenModelsExpenseRepresentation, params: RequestParams = {}) =>
      this.request<void, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/Representation/Update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  travelExpense = {
    /**
     * No description
     *
     * @tags TravelExpense
     * @name GetExpenseFolderPdfsDetail
     * @request GET:/TravelExpense/GetExpenseFolderPdfs/{tacNo}
     * @secure
     */
    getExpenseFolderPdfsDetail: (tacNo: number, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf[], MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/TravelExpense/GetExpenseFolderPdfs/${tacNo}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelExpense
     * @name GetExpenseFolderPdfDetail
     * @request GET:/TravelExpense/GetExpenseFolderPdf/{pdfId}
     * @secure
     */
    getExpenseFolderPdfDetail: (pdfId: number, params: RequestParams = {}) =>
      this.request<ExpenseExpenseFolderPdf, MicrosoftAspNetCoreMvcProblemDetails | void>({
        path: `/TravelExpense/GetExpenseFolderPdf/${pdfId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
