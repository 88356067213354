import {Box, Link, Typography} from '@mui/material';
import {Link as RouterLink, Navigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import ResizingButton from '../sharedComponents/ResizingButton';
import AddExpenseDialog from '../features/expenseFolders/expense/AddExpenseDialog';
import EditExpenseDialog from '../features/expenseFolders/expense/EditExpenseDialog';
import {
	setExpenseFolderStatus,
	setExpenseStatus,
	Status,
} from '../features/expenseFolders/expenseFoldersSlice/expenseFoldersSlice';
import {openAddFolderDialog} from '../features/expenseFolders/models/expenseFoldersUiSlice';
import AddExpenseFolderDialog from '../features/expenseFolders/expenseFolder/AddExpenseFolderDialog';
import EditExpenseFolderDialog from '../features/expenseFolders/expenseFolder/EditExpenseFolderDialog';
import ExpenseFolders from '../features/expenseFolders/expenseFolder/ExpenseFolders';
import SendFolderDialog from '../features/expenseFolders/expenseFolder/SendFolderDialog';
import HomeSnackbars from '../features/expenseFolders/HomeSnackbars';
import HistoryIcon from '@mui/icons-material/History';

const ExpenseFoldersPage = () => {
	const dispatch = useAppDispatch();

	const {t} = useTranslation();

	const shouldGoToSettings = useAppSelector(
		(state) => state.uiReducer.isFirstTimeLogin,
	);

	useEffect(() => {
		dispatch(setExpenseFolderStatus({status: Status.Idle}));
		dispatch(setExpenseStatus({status: Status.Idle}));
	}, []);

	if (shouldGoToSettings) return <Navigate to="/settings"/>;
	
	return (
		<Box>
			<Typography variant="h6">{t('welcome_message')}</Typography>
			<Typography variant="body1">{t('welcome_text')} </Typography>
			<ResizingButton
				onClick={() => dispatch(openAddFolderDialog())}
				variant="text"
				sx={{
					marginLeft: '0rem',
					paddingLeft: '0rem',
					marginTop: '3.2rem',
				}}
			>
				<AddIcon
					sx={{
						marginLeft: '0rem',
						paddingLeft: '0rem',
					}}
				/>
				{t('new_folder')}
			</ResizingButton>
			<Box display="flex" alignItems={'center'}>
				<Typography variant="h6">{t('expense_folders')}</Typography>
				<Link
					component={RouterLink}
					to="/history"
					sx={{color: 'black'}}
					marginLeft={'auto'}
				>
					<ResizingButton variant="text" sx={{textAlign: 'right'}}>
						{t('previously_submitted')}
						<HistoryIcon
							sx={{
								marginLeft: '0.5rem',
								size: 'small',
							}}
						/>
					</ResizingButton>
				</Link>
			</Box>

			<ExpenseFolders/>
			<EditExpenseDialog/>
			<AddExpenseDialog/>
			<AddExpenseFolderDialog/>
			<SendFolderDialog/>
			<EditExpenseFolderDialog/>
			<HomeSnackbars/>
		</Box>
	);
};

export default ExpenseFoldersPage;
